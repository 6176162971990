import { Popper, Grow, ClickAwayListener, Paper, Box } from '@mui/material'
import React, { RefObject } from 'react'
import { SxProps } from '@mui/material/styles'

type PopperComponentProps = {
  anchorMenu: boolean
  setAnchorMenu: React.Dispatch<React.SetStateAction<boolean>>
  anchorRef: RefObject<HTMLButtonElement>
  backgroundColor: string
  children: React.ReactNode
  sx: SxProps
}

export default function PopperComponent(props: PopperComponentProps) {
  const { children, anchorMenu, setAnchorMenu, backgroundColor, anchorRef, sx, ...rest } = props

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setAnchorMenu(false)
  }

  return (
    <Popper
      open={anchorMenu}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom"
      transition
      disablePortal
      sx={sx}
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps}>
          <Paper sx={{ backgroundColor }}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

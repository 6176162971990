import React from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Link as LinkIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'
import { IClipboard, IClipboardItemState } from 'contexts/ClipboardContext'
import floatingActionMenuStyle from 'shared/components/floatingActionMenuStyle'
import { colors } from 'shared/theme'
import ITermData from 'types/ITermData'
import IStationData from 'types/IStationData'
import Checkbox from 'components/control/Checkbox'

type FloatingActionMenuTermProps = {
  station: IStationData
  term: ITermData
  clipboard: IClipboard
}

export default function FloatingActionMenuTerm({
  station,
  term,
  clipboard,
}: FloatingActionMenuTermProps): JSX.Element {
  const clipboardTermState: IClipboardItemState<ITermData> | null = clipboard.terms.getItemState(
    term?.id,
  )
  const isCopiedToClipboard: boolean =
    clipboardTermState !== null && clipboardTermState.action === 'copy'
  const isCutToClipboard: boolean =
    clipboardTermState !== null && clipboardTermState.action === 'cut'

  const getTermHyperlink = () => (term ? term.file || term.image || term.hyperlink : null)
  const termHyperlink = getTermHyperlink()

  const handleCopyCheckboxClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (term) {
      if (!isCopiedToClipboard && !isCutToClipboard) {
        clipboard.terms.addItem('copy', term)
      } else {
        clipboard.terms.removeItem(term.id)
      }
    }
  }

  return (
    <>
      <Box position="relative">
        <Box sx={floatingActionMenuStyle(station?.is_pinned, '3px', '8px')}>
          {term && termHyperlink && (
            <Box display="flex" alignItems="flex-start" justifyContent="center">
              <Tooltip title={t`Launch`} disableInteractive>
                <IconButton onClick={() => window.open(termHyperlink, '_blank')} sx={{ p: 0 }}>
                  <LinkIcon sx={{ p: '0px', color: colors.black.A500, fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          {term && (
            <Box display="flex" alignItems="flex-start" justifyContent="center">
              <Tooltip
                title={
                  isCopiedToClipboard || isCutToClipboard
                    ? t`Remove Term from clipboard`
                    : t`Add Term to clipboard`
                }
                disableInteractive
              >
                <Box sx={{ opacity: 0.4 }}>
                  <Checkbox
                    onClick={(e) => handleCopyCheckboxClick(e)}
                    checked={isCopiedToClipboard || isCutToClipboard}
                    color="primary"
                    sx={{ zIndex: '1' }}
                  />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme from 'shared/theme'

const infiniteScrollStyle = (
  isPinnedStationDrawerOpen: boolean,
  screenHeight: number,
  topHeaderHeight: number,
): SxProps =>
  css({
    overflow: isPinnedStationDrawerOpen ? 'scroll' : 'unset',
    width: '100%',
    maxHeight: isPinnedStationDrawerOpen
      ? `calc(${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight} - 24px)`
      : 0,
    '& .infinite-scroll-component__outerdiv': {
      height: isPinnedStationDrawerOpen ? 'auto !important' : '100% !important',
    },

    '& .infinite-scroll-component': {
      height: '100% !important',
      overflow: 'unset !important',
    },
  }) as SxProps
export default infiniteScrollStyle

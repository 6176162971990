import {
  FormControl,
  FormHelperText,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListProps,
} from '@mui/material'
import { Control, Controller, DeepRequired, FieldErrorsImpl } from 'react-hook-form'
import React from 'react'

type CheckBoxListComponentProps = ListProps & {
  control: Control<unknown>
  context: string[]
  requiredMsg?: string
  name: string
  errors?: FieldErrorsImpl<DeepRequired<unknown>>
  options: []
  InputProps?: {}
  helperText?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export default function CheckBoxListComponent(props: CheckBoxListComponentProps) {
  const { control, requiredMsg, name, errors, options, helperText, sx, ...rest } = props
  const [context, setContext] = React.useState<string[]>(props.context)

  const getValue = (selection: string, value: string[]) => {
    if (value) {
      return value.indexOf(selection) >= 0
    }
    return ''
  }

  return (
    <FormControl>
      <List sx={sx} {...rest}>
        {options.map((option: { id: string; name: string }, index: number) => (
          <Controller
            key={option.id}
            control={control}
            name={`ai_context_lists` as never}
            rules={{
              required: requiredMsg ?? false,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ListItem
                key={option.id}
                value={option.id}
                disablePadding
                sx={{ width: options.length > 5 ? '48%' : '100%', margin: '0' }}
                dense
              >
                <ListItemButton role={undefined}>
                  <ListItemIcon>
                    <Checkbox
                      onChange={(e) => {
                        onChange(e)
                        if (props.onChange) props.onChange(e)
                        setContext(props.context)
                      }}
                      onBlur={onBlur}
                      inputRef={ref}
                      checked={!!getValue(option.id, context)}
                      value={option.id}
                    />
                  </ListItemIcon>
                  <ListItemText id={option.id} primary={option.name} />
                </ListItemButton>
              </ListItem>
            )}
          />
        ))}
      </List>
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>
          {errors[name] ? (errors[name] as { message: string })?.message : helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

import { MutatorCallback } from 'swr'
import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import ITermData from 'types/ITermData'
import IToolData from 'types/IToolData'

type NewValue<T> = T | ((oldData: T) => T)

export function stationMutate(
  stationId: string,
  newValue: NewValue<IStationData>,
): MutatorCallback<IStudyData> {
  return (oldState) => {
    const stations = [...oldState.stations]
    const index = stations.findIndex((s) => s.id === stationId)
    stations[index] = typeof newValue === 'function' ? newValue(stations[index]) : newValue
    return { ...oldState, stations }
  }
}

export function toolMutate(
  stationId: string,
  toolId: string,
  newValue: NewValue<IToolData>,
): MutatorCallback<IStudyData> {
  return (oldState) =>
    stationMutate(stationId, (oldStation) => {
      const tools = [...oldStation.tools]
      const index = tools.findIndex((t) => t.id === toolId)
      tools[index] = typeof newValue === 'function' ? newValue(tools[index]) : newValue
      return { ...oldStation, tools }
    })(oldState)
}

export function termMutate(
  stationId: string,
  toolId: string,
  termId: string,
  newValue: NewValue<ITermData>,
): MutatorCallback<IStudyData> {
  return (oldState) =>
    toolMutate(stationId, toolId, (oldTool) => {
      const terms = [...oldTool.terms]
      const index = terms.findIndex((t) => t.id === termId)
      terms[index] = typeof newValue === 'function' ? newValue(terms[index]) : newValue
      return { ...oldTool, terms }
    })(oldState)
}

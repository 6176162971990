import DialogComponent from 'components/dialog/DialogComponent'
import React, { useRef } from 'react'
import { Box } from '@mui/material'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import AIForm from 'components/form/AIForm'
import IToolData from 'types/IToolData'

type AIDialogProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  tool: IToolData
  open: boolean
  onModalClose: () => void
}
export default function AIDialog(props: AIDialogProps) {
  const { study, tool, mutateStudy, open, onModalClose } = props
  const dialogRef = useRef(null)

  return (
    <DialogComponent
      open={open}
      onModalClose={onModalClose}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={false}
    >
      <Box sx={{ width: { xs: 'auto', md: '500px' } }}>
        {tool && (
          <AIForm study={study} tool={tool} onModalClose={onModalClose} mutateStudy={mutateStudy} />
        )}
      </Box>
    </DialogComponent>
  )
}

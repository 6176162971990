import React from 'react'
import IStudyData from 'types/IStudyData'
import IStationData from 'types/IStationData'
import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'
import { ActionMenuContextObject } from 'contexts/ActionMenuContext'
import { t } from '@lingui/macro'

const orderingHelper = {
  orderTerms<T extends ITermData>(items: T[], itemMoved: T, newOrder: number): T[] {
    // Create a copy of the items array to avoid mutating the original array
    const itemsCopy = [...items]

    // Find the item and remove it from the array
    const movingItemIndex = itemsCopy.findIndex((item) => item.id === itemMoved.id)
    if (movingItemIndex !== -1) {
      itemsCopy.splice(movingItemIndex, 1)
    }

    // Insert the item at the new position
    const boundedNewPosition = Math.max(0, Math.min(itemsCopy.length, newOrder - 1))
    itemsCopy.splice(boundedNewPosition, 0, { ...itemMoved, position: newOrder })

    // Adjust positions for all items
    // Since we've already placed the moved study in its new position,
    // we can now iterate through the array once and assign positions
    return itemsCopy.map((item, index) => ({
      ...item,
      position: index + 1,
    }))
  },

  sortByLanguage(val1: string, val2: string) {
    if (val1.endsWith(t`language-code`) && !val2.endsWith(t`language-code`)) return -1
    if (val2.endsWith(t`language-code`) && !val1.endsWith(t`language-code`)) return 1
    if (val1.slice(-3) !== val2.slice(-3))
      return val1.slice(-3).toLowerCase() > val2.slice(-3).toLowerCase() ? 1 : -1
    return val1.toLowerCase() > val2.toLowerCase() ? 1 : -1
  },

  findTerm<T extends ITermData>(
    study: IStudyData,
    itemToFindId: string,
  ): {
    index: number
    value: T
  } | null {
    if (!study?.stations) {
      return null
    }

    for (const station of study.stations) {
      for (const tool of station.tools) {
        const index = tool.terms.findIndex((item) => item.id === itemToFindId)
        const value = tool.terms.find((item) => item.id === itemToFindId)
        if (value !== undefined) {
          return { index, value: value as T }
        }
      }
    }

    return null
  },

  findStationByRowColumn(
    stations: IStationData[],
    row: number,
    column: number,
  ): IStationData | null {
    if (stations) {
      return stations?.find((station) => station.row === row && station.column === column)
    }
    return null
  },

  findToolByRowColumn(station: IStationData, row: number, column: number): IToolData | null {
    if (station) {
      return station.tools?.find((tool) => tool.row === row && tool.column === column)
    }
    return null
  },

  getRowColumnByKey(key: string): { row: number; column: number } {
    const parts = key.split('-')
    if (parts.length >= 2) {
      const row = Number(parts[parts.length - 2])
      const column = Number(parts[parts.length - 1])
      return { row, column }
    }
    return { row: -1, column: -1 }
  },

  updateStudyStations(
    study: IStudyData,
    index: number,
    viewSizeDimension: 'row' | 'column',
    orientation: 'left' | 'right' | 'top' | 'bottom',
    actionMenuContext: ActionMenuContextObject,
    setActionMenuContext: React.Dispatch<React.SetStateAction<ActionMenuContextObject>>,
  ): IStudyData {
    const updateDimension = (station: IStationData, dimension: 'row' | 'column') => {
      let shouldIncrement: boolean

      if (dimension === 'column') {
        shouldIncrement = orientation === 'left' ? station.column >= index : station.column > index
      } else {
        shouldIncrement = orientation === 'top' ? station.row >= index : station.row > index
      }

      return {
        ...station,
        [dimension]: shouldIncrement ? station[dimension] + 1 : station[dimension],
      }
    }

    const updatedStudy = {
      ...study,
      stations: study.stations.map((station) => {
        const newStation = updateDimension(station, viewSizeDimension)

        if (newStation.id === actionMenuContext.station?.id) {
          setActionMenuContext((currentActionMenuContext) => ({
            ...currentActionMenuContext,
            stationGridItem: `station-grid-item-${newStation.row}-${newStation.column}`,
            station: newStation,
          }))
        }

        return newStation
      }),
    }

    if (!actionMenuContext.station && actionMenuContext.stationGridItem) {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.stationGridItem)
      setActionMenuContext((currentActionMenuContext) => ({
        ...currentActionMenuContext,
        stationGridItem: `station-grid-item-${orientation === 'top' ? row + 1 : row}-${
          orientation === 'left' ? column + 1 : column
        }`,
      }))
    }

    return updatedStudy
  },

  updateStudyTools(
    study: IStudyData,
    index: number,
    viewSizeDimension: 'row' | 'column',
    orientation: 'left' | 'right' | 'top' | 'bottom',
    actionMenuContext: ActionMenuContextObject,
    setActionMenuContext: React.Dispatch<React.SetStateAction<ActionMenuContextObject>>,
  ): IStudyData {
    const updateDimension = (tool: IToolData, dimension: 'row' | 'column') => {
      let shouldIncrement: boolean

      if (dimension === 'column') {
        shouldIncrement = orientation === 'left' ? tool.column >= index : tool.column > index
      } else {
        shouldIncrement = orientation === 'top' ? tool.row >= index : tool.row > index
      }

      return {
        ...tool,
        [dimension]: shouldIncrement ? tool[dimension] + 1 : tool[dimension],
      }
    }

    const updatedStudy = {
      ...study,
      stations: study.stations.map((station) => {
        const updatedTools = station.tools.map((tool) => {
          if (tool.id === actionMenuContext.tool?.id) {
            const newTool = updateDimension(tool, viewSizeDimension)
            setActionMenuContext((currentActionMenuContext) => ({
              ...currentActionMenuContext,
              toolGridItem: `${actionMenuContext.station.id}-tool-droppable-${newTool.row}-${newTool.column}`,
              tool: newTool,
            }))
            return newTool
          }
          if (station.id === actionMenuContext.station?.id) {
            return updateDimension(tool, viewSizeDimension)
          }

          return tool
        })

        return { ...station, tools: updatedTools }
      }),
    }

    if (!actionMenuContext.tool && actionMenuContext.toolGridItem) {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
      setActionMenuContext((currentActionMenuContext) => ({
        ...currentActionMenuContext,
        toolGridItem: `${actionMenuContext.station.id}-tool-droppable-${
          orientation === 'top' ? row + 1 : row
        }-${orientation === 'left' ? column + 1 : column}`,
      }))
    }

    return updatedStudy
  },

  getStudyCurrentCount(
    prevStudy: IStudyData,
    station: IStationData,
    viewType: 'study' | 'station',
    dimension: 'column' | 'row',
  ) {
    if (viewType === 'study') {
      return dimension === 'column' ? prevStudy.column_count : prevStudy.row_count
    }
    if (viewType === 'station' && station) {
      return dimension === 'column' ? station.column_count : station.row_count
    }
    return 0
  },

  updateStudyViewSize(
    study: IStudyData,
    dimension: 'column' | 'row',
    viewType: 'study' | 'station',
    operation: 'add' | 'remove',
    station?: IStationData,
  ): IStudyData {
    let currentCount = this.getStudyCurrentCount(study, station, viewType, dimension)

    if (operation === 'add') {
      currentCount += 1
    } else if (operation === 'remove' && currentCount > 1) {
      currentCount -= 1
    }

    const updatedStudy = { ...study }

    if (viewType === 'study') {
      updatedStudy[`${dimension}_count`] = currentCount
    } else if (viewType === 'station' && station) {
      const stationIndex = study.stations?.findIndex(
        (prevStudyStation) => station.id === prevStudyStation.id,
      )

      if (stationIndex !== -1 && stationIndex !== undefined) {
        updatedStudy.stations = [...study.stations]
        updatedStudy.stations[stationIndex] = {
          ...updatedStudy.stations[stationIndex],
          [`${dimension}_count`]: currentCount,
        }
      }
    }
    return updatedStudy
  },

  validatePinnedStationsOrdering(pinnedStations: IStationData[]): boolean {
    let hasDuplicate = false
    const pinOrders = pinnedStations.map((station) => station.pin_order)
    const pinOrdersSet = new Set()

    // Check for duplicate
    for (const pinOrder of pinOrders) {
      if (pinOrdersSet.has(pinOrder)) {
        hasDuplicate = true
      }
      pinOrdersSet.add(pinOrder)
    }

    if (hasDuplicate) {
      return false
    }

    // Check for continuity
    pinOrders.sort((a, b) => a - b)
    return (
      pinOrders[0] === 1 && pinOrders[pinOrders.length - 1] - pinOrders[0] + 1 === pinOrders.length
    )
  },

  getPinnedStationsOrdering(pinnedStations: IStationData[]): IStationData[] {
    const sortedStations = pinnedStations.sort((a, b) => a.pin_order - b.pin_order)

    // Correct the pin_order to ensure it starts from 1 and is continuous.
    return sortedStations.map((station, index) => {
      station.pin_order = index + 1
      return station
    })
  },
}

export default orderingHelper

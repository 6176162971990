import React, { useEffect, useReducer } from 'react'
import { Box, Typography, Container, IconButton, Tooltip, Button } from '@mui/material'
import { t } from '@lingui/macro'
import ProjectMenu from 'components/SideMenuDrawer'
import { colors } from 'shared/theme'
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  ArrowBack as ArrowBackIcon,
  SettingsOutlined as SettingsOutlinedIcon,
} from '@mui/icons-material'
import useTeams, { useTeam } from 'hooks/useTeams'
import useStudyLists from 'hooks/useStudyLists'
import TeamForm from 'components/form/TeamForm'
import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'
import paths from 'utils/paths'
import TeamView from 'pages/team/TeamView'

const initialState = {
  showTeamView: false,
  showTeamAdd: false,
  showTeamSetting: false,
}

type Action = { type: 'TEAM_VIEW' } | { type: 'TEAM_ADD' } | { type: 'TEAM_SETTING' }

function reducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case 'TEAM_VIEW':
      return {
        ...initialState,
        showTeamView: true,
      }

    case 'TEAM_ADD':
      return {
        ...initialState,
        showTeamAdd: true,
      }

    case 'TEAM_SETTING':
      return {
        ...initialState,
        showTeamSetting: true,
      }

    default:
      return initialState
  }
}

function Team(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  const { id } = useParams()
  const { studyLists } = useStudyLists()
  const { teams } = useTeams()
  const { team } = useTeam(id)
  const [viewState, dispatchViewState] = useReducer(reducer, initialState)
  const location = useLocation()

  useEffect(() => {
    if (location) {
      const { pathname } = location
      if (pathname.includes('/setting/') && id) dispatchViewState({ type: 'TEAM_SETTING' })
      else if (pathname.includes('/add/')) dispatchViewState({ type: 'TEAM_ADD' })
      else if (id) {
        dispatchViewState({ type: 'TEAM_VIEW' })
      }
    }
  }, [location, id])

  const getPageTitle = (): string => {
    if (viewState.showTeamView && team) return team.data.name

    if (viewState.showTeamSetting && team) return t`Team settings`

    return t`Create new team`
  }

  const getBackPath = (): string => {
    if (viewState.showTeamSetting && team) return paths.frontend.teams(team.data.id)

    return '-1'
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ padding: { xs: '0 24px 0 24px', md: '0 24px 0 0' } }}
    >
      <Box display="flex">
        {/* LEFT SECTION */}
        <ProjectMenu studyLists={studyLists?.data} teams={teams?.data} />

        {/* RIGHT SECTION */}
        <Box sx={sideMenuDrawerRightBoxStyle()}>
          <Box display="flex" flexDirection="column">
            {/* TOP BAR */}
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="flex-start"
              gap={{ xs: '24px', md: '0' }}
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              <Box
                display="flex"
                sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <Box display="flex" alignItems="flex-start" width="40px" margin="4px 0 0 0">
                  <Tooltip title={t`Back`} disableInteractive>
                    <IconButton color="inherit" onClick={() => navigate(getBackPath())}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Box display="flex">
                    <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                      {getPageTitle()}
                    </Typography>
                  </Box>

                  {viewState.showTeamView && (
                    <Box display="flex" margin="16px 0 0 0" gap="24px">
                      <Typography variant="h5" fontWeight={500} color="text.secondary">
                        {t`Members`}
                      </Typography>

                      {team?.data?.users.map((user) => (
                        <Typography variant="h5" color="text.secondary" key={user.name}>
                          {user.name}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>

              {viewState.showTeamView && (
                <Box>
                  <Button
                    onClick={() => navigate(paths.frontend.teamsSetting(team?.data?.id))}
                    variant="containedLightBlue"
                    type="button"
                    sx={{ width: 'auto', padding: '8px 24px' }}
                    startIcon={<SettingsOutlinedIcon />}
                  >
                    <Typography variant="body1" fontWeight={500} noWrap>
                      {t`Team settings`}
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>

            {/* BODY */}
            <Box margin="8px 0 0 0">
              {viewState.showTeamView && (
                <TeamView projects={team?.data.study_lists} teams={teams?.data} team={team?.data} />
              )}

              {viewState.showTeamAdd && (
                <Box margin="32px 0 0 0" sx={{ width: { xs: '100%', md: '50%' } }}>
                  <TeamForm />
                </Box>
              )}

              {viewState.showTeamSetting && team && (
                <Box margin="32px 0 0 0" sx={{ width: { xs: '100%', md: '50%' } }}>
                  <TeamForm team={team ? team?.data : null} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(Team)

import React, { useEffect, useRef } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { t } from '@lingui/macro'
import { useErrorContext } from 'contexts/ErrorContext'
import { IClipboard } from 'contexts/ClipboardContext'
import IStationData from 'types/IStationData'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import IToolData from 'types/IToolData'
import toolStyle from 'shared/components/toolStyle'
import theme, { colors } from 'shared/theme'
import { KeyedMutator } from 'swr'
import TextFieldComponent from 'components/control/TextFieldComponent'
import { toolMutate } from 'hooks/studyMutations'
import IStudyData from 'types/IStudyData'
import ITermData from 'types/ITermData'
import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import ToolDataService from 'data-services/ToolDataService'
import TermDroppable from 'components/term/TermDroppable'
import TermDataService from 'data-services/TermDataService'
import useWindowDimensions from 'utils/useWindowDimensions'
import { FormStudyData } from 'components/form/StudyDataForm'
import StationDataService from 'data-services/StationDataService'
import FloatingActionMenuTool from 'components/floating-action-menu/FloatingActionMenuTool'
import AIDialog from 'components/dialog/AIDialog'

type ToolProps = {
  study: IStudyData
  tool: IToolData
  station: IStationData
  parentRow: number
  parentColumn: number
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

type FormToolFields = {
  prompt: string
}

function Tool({
  study,
  tool,
  station,
  parentRow,
  parentColumn,
  mutateStudy,
  clipboard,
  viewType,
}: ToolProps): JSX.Element {
  const isInitialToolRender = useRef<boolean>(true)
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const { isLoading } = useStudyStatesContext()
  const { handleAsyncError } = useErrorContext()
  const { height: screenHeight } = useWindowDimensions()
  const [showAIDialog, setShowAIDialog] = React.useState<boolean>(false)

  const toolKey = `${tool.station}-tool${viewType === 'pin' ? '-pin' : ''}-${tool.row}-${
    tool.column
  }`

  const {
    control: promptControl,
    getValues,
    reset,
  } = useForm<FormToolFields>({
    mode: 'onBlur',
    defaultValues: {
      prompt: tool.prompt,
    },
  })
  const { control } = useFormContext<FormStudyData>()

  useEffect(() => {
    if (isInitialToolRender.current) {
      isInitialToolRender.current = false
    } else if (clipboard.tools.getItemState(tool.id) !== null) {
      clipboard.tools.updateItemData(tool)
    }
  }, [tool])

  const isToolActive = (): boolean =>
    !!actionMenuContext && actionMenuContext.tool?.id === tool.id && !actionMenuContext.term

  function createNewTerm() {
    return {
      id: '',
      term: t`New term`,
      order: 0,
      note: '',
      hyperlink: '',
      tool: tool.id,
      is_error: false,
      agent_meta: '',
      agent_model: '',
      agent_tokens: '',
    }
  }

  const addTerm = (newTerm: ITermData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      TermDataService.addTerm(newTerm)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleToolClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActionMenuContext({
      stationGridItem: `station-grid-item-${station.row}-${station.column}`,
      station,
      toolGridItem: `${station.id}-tool-droppable-${tool.row}-${tool.column}`,
      tool,
      stationListId: null,
      term: null,
    })
  }

  const hasRobot = (): boolean => tool.tool_type !== ''

  const isProcessingText = (): string => {
    if (tool.is_processing) return t`Processing...`
    if (hasRobot()) return t`Type question...`
    return t`Type text...`
  }

  const handleAIPopupClick = (): void => {
    console.log(study)
    setShowAIDialog(true)
  }

  const handlePromptSubmit = (): void => {
    if (!isLoading) {
      if (hasRobot()) {
        const toolData = getValues() as IToolData

        StationDataService.update(station.id, { ...station, subject: toolData.prompt })
          .then(() => {
            ToolDataService.processTool(tool.id, toolData)
              .then(({ data }) => {
                void mutateStudy(toolMutate(tool.station, tool.id, data), false)
                if (actionMenuContext.tool?.id === tool.id) {
                  setActionMenuContext((prevActionMenuContext) => ({
                    ...prevActionMenuContext,
                    tool: data,
                  }))
                }
              })
              .catch((error: Error) => {
                handleAsyncError(error.message)
              })
          })
          .catch((error: Error) => {
            handleAsyncError(error.message)
          })
      } else {
        const newTerm = createNewTerm()
        newTerm.term = getValues('prompt')
        addTerm(newTerm).then(() =>
          mutateStudy().then((studyResponse) => {
            reset()
            if (actionMenuContext.tool?.id === tool.id) {
              const newStation = studyResponse.stations.find(
                (stationObject) => stationObject.id === station.id,
              )
              const newTool = newStation?.tools.find((toolObject) => toolObject.id === tool.id)
              setActionMenuContext((prevActionMenuContext) => ({
                ...prevActionMenuContext,
                tool: newTool,
              }))
            }
          }),
        )
      }
    }
  }

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }
    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const config = {
      title: {
        ...theme.typography.h5,
        fontWeight: 500,
        color: 'black',
      },
      note: {
        ...theme.typography.body1,
        fontWeight: 400,
        color: colors.gray.A600,
      },
    }

    const defaultStyle = {}
    const chosenStyle = config[name] || defaultStyle

    return {
      ...generalProps,
      style: {
        ...generalStyles,
        ...chosenStyle,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...chosenStyle,
        },
      },
    }
  }

  function getToolTitle() {
    const personality = tool.ai_personality_title ? `${tool.ai_personality_title}` : `Generic AI`
    return `${personality}`
  }

  return (
    tool && (
      <Draggable
        draggableId={toolKey}
        index={parentColumn * 1000 + parentRow * 1000 + tool.column + tool.row}
        key={toolKey}
        isDragDisabled
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={toolStyle(
              screenHeight,
              study.row_count,
              station.row_count,
              isToolActive(),
              snapshot.isDragging,
            )}
            onClick={handleToolClick}
          >
            <Box
              display="flex"
              width="100%"
              sx={{
                background: 'transparent',
                padding: '0',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <FormTextFieldBlur
                name={`tools[${tool.id}].title`}
                autoComplete="off"
                placeholder={
                  tool.tool_type === 'ai' ? t`This is your helpful colleague.` : t`Tool title`
                }
                InputProps={getInputProps('title')}
                variant="standard"
                multiline
                control={control as never}
              />
              <FloatingActionMenuTool station={station} tool={tool} clipboard={clipboard} />
            </Box>

            <Box
              width="100%"
              sx={{
                background: 'transparent',
                padding: '0',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <FormTextFieldBlur
                name={`tools[${tool.id}].note`}
                autoComplete="off"
                placeholder={
                  tool.tool_type === 'ai'
                    ? t`Click on the button below to change its configuration`
                    : t`Tool description`
                }
                InputProps={getInputProps('note')}
                variant="standard"
                multiline
                control={control as never}
              />
            </Box>
            <Box display="flex" sx={{ width: '100%' }}>
              {tool.tool_type === 'ai' ? (
                <Box display="flex" sx={{ width: '100%' }}>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={handleAIPopupClick}
                    sx={{ textAlign: 'left' }}
                  >
                    {getToolTitle()}
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Box>

            <Box
              margin="8px 0 0 0"
              padding="4px 8px"
              sx={{
                display: 'flex',
                background: colors.gray.A700,
                borderRadius: '4px 4px 0px 0px',
                borderBottom: `1px solid ${colors.black.A500}`,
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="h3" sx={{ color: colors.black.A600 }}>
                  {hasRobot() ? t`Prompt` : t`New Term`}
                </Typography>

                <TextFieldComponent
                  label=""
                  placeholder={isProcessingText()}
                  className="MuiInput-test"
                  control={promptControl as never}
                  name="prompt"
                  type="text"
                  requiredMsg={t`Term is required`}
                  multiline
                  sx={{ width: '100%' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault()
                      handlePromptSubmit()
                    }
                  }}
                  disabled={tool.is_processing}
                />
              </Box>
            </Box>
            {tool.is_processing && <LinearProgress sx={{ margin: '8px 0 0 0' }} />}

            <TermDroppable
              station={station}
              tool={tool}
              mutateStudy={mutateStudy}
              clipboard={clipboard}
              viewType={viewType}
            />
            <AIDialog
              study={study}
              tool={tool}
              mutateStudy={mutateStudy}
              open={showAIDialog}
              onModalClose={() => setShowAIDialog(false)}
            />
          </Box>
        )}
      </Draggable>
    )
  )
}

export default React.memo(Tool)

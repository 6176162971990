import { AxiosResponse } from 'axios'
import { omit } from 'lodash'
import axiosInstance from 'utils/axios'
import IStudyListData from 'types/IStudyListData'
import paths from 'utils/paths'
import IStudyData, { IStudyImportData } from 'types/IStudyData'

const importStudyFromTemplateStudyList = (
  id: string,
  gridItemData: IStudyImportData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.studyList.studyListsImportStudyFromTemplate(id),
    gridItemData,
  )

const addStudyList = (studyList: IStudyListData): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.post<IStudyListData, AxiosResponse<IStudyListData>>(
    `${paths.backend.studyList.studyLists()}`,
    studyList,
  )

const updateStudyList = (
  id: string,
  data: IStudyListData,
): Promise<AxiosResponse<IStudyListData>> =>
  axiosInstance.put<IStudyListData, AxiosResponse<IStudyListData>>(
    `${paths.backend.studyList.studyLists(id)}`,
    omit(data, ['study_ids']),
  )

const deleteStudyList = (id: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(`${paths.backend.studyList.studyLists(id)}`)

const StudyListDataService = {
  importStudyFromTemplateStudyList,
  addStudyList,
  updateStudyList,
  deleteStudyList,
}

export default StudyListDataService

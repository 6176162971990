import React, { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  DeleteOutlined as DeleteIcon,
  Terminal as TerminalIcon,
  SystemUpdateAlt as SystemUpdateIcon,
  GridViewOutlined as GridViewIcon,
  SourceOutlined as SourceIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import { KeyedMutator, MutatorCallback } from 'swr'
import { useErrorContext } from 'contexts/ErrorContext'
import IStudyData from 'types/IStudyData'
import { colors } from 'shared/theme'
import TemplateDialog from 'components/control/TemplateDialog'
import paths from 'utils/paths'
import ConfirmDialog from 'components/control/ConfirmDialog'
import { DeleteDialogType } from 'components/study/StudyMoreActionMenu'
import StudyDataService from 'data-services/StudyDataService'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

type ActionMenuStudyProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  mutateStudyList: MutatorCallback
}

export default function ActionMenuStudy({
  study,
  mutateStudy,
  mutateStudyList,
}: ActionMenuStudyProps): JSX.Element {
  const { handleAsyncError } = useErrorContext()
  const actionButtonDeleteRef = useRef<HTMLButtonElement>(null)
  const [actionButtonDeleteOpen, setActionButtonDeleteOpen] = useState(false)
  const actionButtonImportStudyRef = useRef<HTMLButtonElement>(null)
  const [actionButtonImportStudyOpen, setActionButtonImportStudyOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<DeleteDialogType>({ open: false })
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const importStudyFromTemplateExisting = (templateId: string): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      StudyDataService.importStudyFromTemplateStudy(study.id, {
        template_id: templateId,
      })
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleOpenTemplateDialog = (): void => {
    setTemplateDialogOpen(true)
  }
  const handleCloseTemplateDialog = (): void => {
    setTemplateDialogOpen(false)
  }

  const handleDeleteStudyConfirmClick = () => {
    const studyClone = { ...study }
    StudyDataService.deleteStudy(studyClone.id)
      .then(() => {
        mutateStudyList().then(() => {
          setDeleteDialogOpen({ open: false })
          navigate(paths.frontend.studyList(studyClone.study_list))
          enqueueSnackbar(
            `Study "${
              studyClone.title && studyClone.title !== '' ? studyClone.title : t`New study`
            }" successfully deleted`,
            { variant: 'success' },
          )
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  const handleDeleteStudyStationsConfirmClick = () => {
    StudyDataService.deleteStudyStations(study.id)
      .then(({ data: studyResponse }) => {
        mutateStudy(studyResponse, false).then(() => {
          enqueueSnackbar(t`Station(s) successfully deleted`, { variant: 'success' })
          setDeleteDialogOpen({ open: false })
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  const handleDeleteStudyClick = () => {
    setDeleteDialogOpen({ open: true, deleteType: 'study' })
    setActionButtonDeleteOpen(false)
  }

  const handleDeleteStationsClick = () => {
    setDeleteDialogOpen({ open: true, deleteType: 'station' })
    setActionButtonDeleteOpen(false)
  }

  return (
    <Box
      display="flex"
      gap={{ xs: '8px', mobile: '32px' }}
      flexGrow="1"
      alignItems="center"
      flexWrap={{ xs: 'wrap', mobile: 'nowrap' }}
    >
      <ActionMenuButton
        actionMenuButtonRef={actionButtonImportStudyRef}
        actionMenuButtonAnchorOpen={actionButtonImportStudyOpen}
        setActionMenuButtonAnchorOpen={setActionButtonImportStudyOpen}
        handleActionMenuButtonClick={() =>
          setActionButtonImportStudyOpen(!actionButtonImportStudyOpen)
        }
        StartIcon={SystemUpdateIcon}
        title={t`Import Study`}
        actionMenuItems={
          <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
            {/* <Button
                  onClick={() => console.log('TODO: IMPORT FILE')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={<DownloadingIcon sx={{ color: colors.black.A500 }} />}
                  sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography
                    variant="body1"
                    fontWeight="400"
                    color="text.primary"
                    textAlign="left"
                    margin="0 0 0 8px"
                >
                  {t`From File`}
                </Typography>
              </Button> */}

            <Button
              onClick={handleOpenTemplateDialog}
              variant="text"
              type="button"
              disableRipple
              startIcon={<TerminalIcon sx={{ color: colors.black.A500 }} />}
              sx={{ width: '100%', padding: '8px 24px' }}
            >
              <Typography
                variant="body1"
                fontWeight="400"
                color="text.primary"
                textAlign="left"
                margin="0 0 0 8px"
              >
                {t`From Template`}
              </Typography>
            </Button>
          </Box>
        }
      />

      <ActionMenuButton
        actionMenuButtonRef={actionButtonDeleteRef}
        actionMenuButtonAnchorOpen={actionButtonDeleteOpen}
        setActionMenuButtonAnchorOpen={setActionButtonDeleteOpen}
        handleActionMenuButtonClick={() => setActionButtonDeleteOpen(!actionButtonDeleteOpen)}
        StartIcon={DeleteIcon}
        title={t`Delete`}
        isDanger
        actionMenuItems={
          <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
            <Box display="flex" gap="8px" flexDirection="column">
              <Button
                onClick={handleDeleteStationsClick}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <GridViewIcon
                    sx={{ color: colors.black.A500 }}
                    className="MuiButton-startIcon-danger"
                  />
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography
                  variant="body1"
                  fontWeight="400"
                  color="text.primary"
                  textAlign="left"
                  margin="0 0 0 8px"
                  className="MuiTypography-danger"
                >
                  {t`Delete Stations`}
                </Typography>
              </Button>

              <Button
                onClick={handleDeleteStudyClick}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SourceIcon
                    sx={{ color: colors.black.A500 }}
                    className="MuiButton-startIcon-danger"
                  />
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography
                  variant="body1"
                  fontWeight="400"
                  color="text.primary"
                  textAlign="left"
                  margin="0 0 0 8px"
                  className="MuiTypography-danger"
                >
                  {t`Delete Study`}
                </Typography>
              </Button>
            </Box>
          </Box>
        }
      />

      {templateDialogOpen && (
        <TemplateDialog
          description={t`Add a study (including its stations, tools and terms) from a template.`}
          templatesEndpoint={paths.backend.study.studiesTemplates}
          open={templateDialogOpen}
          onClose={handleCloseTemplateDialog}
          onImportFromTemplate={importStudyFromTemplateExisting}
          title={t`Import study from template`}
        />
      )}

      <ConfirmDialog
        confirmColor="secondary"
        confirmText={deleteDialogOpen.deleteType === 'study' ? t`Delete study` : t`Delete`}
        description={
          deleteDialogOpen.deleteType === 'study'
            ? t`Are you sure you want to delete this study?`
            : t`Are you sure you want to delete all stations in this study?`
        }
        open={deleteDialogOpen.open}
        onClose={() => setDeleteDialogOpen({ open: false, deleteType: null })}
        onConfirm={
          deleteDialogOpen.deleteType === 'study'
            ? handleDeleteStudyConfirmClick
            : handleDeleteStudyStationsConfirmClick
        }
        title={deleteDialogOpen.deleteType === 'study' ? t`Study` : t`Delete Stations`}
        confirmButtonVariant="containedRed"
        cancelButtonVariant="containedWhite"
      />
    </Box>
  )
}

import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { t } from '@lingui/macro'
import FileUpload from 'components/control/FileUpload'
import { colors } from 'shared/theme'
import DialogComponent from 'components/dialog/DialogComponent'

export type FileUploadDialogProps = {
  accept?: string
  description: string
  maxSize?: number
  onClose: () => void
  onUpload: (
    json: string | File,
    setErrorList: React.Dispatch<React.SetStateAction<string[]>>,
  ) => void
  open: boolean
  title: string
  returnFile?: boolean
}

function FileUploadDialog({
  accept = 'application/JSON',
  description,
  maxSize = 1048576, // 1MB (1024 * 1024)
  onClose,
  onUpload,
  open,
  title,
  returnFile = false,
}: FileUploadDialogProps): JSX.Element {
  const dialogRef = useRef(null)

  const handleUpload = (
    file: File,
    setErrorList: React.Dispatch<React.SetStateAction<string[]>>,
  ): void => {
    const formattedFileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    const formattedAccept = accept.toLowerCase()
    console.log(accept, ': ', formattedFileType)
    if (accept && !formattedAccept.includes(formattedFileType)) {
      setErrorList([t`Invalid file type`])
    } else if (file.size > maxSize) {
      setErrorList([t`File too large`])
    } else {
      const reader: FileReader = new FileReader()

      if (returnFile) {
        onUpload(file, setErrorList)
      } else {
        reader.onload = (e: ProgressEvent<FileReader>): void => {
          if (e.target) {
            const json: string = e.target.result as string
            onUpload(json, setErrorList)
          }
        }
        reader.readAsText(file)
      }
    }
  }

  return (
    <DialogComponent
      open={open}
      onModalClose={onClose}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={false}
      dialogTitleNode={
        <Box padding="24px 24px 0 24px">
          <Typography variant="h1" fontWeight="400" sx={{ color: colors.black.A600 }}>
            {title}
          </Typography>
        </Box>
      }
    >
      <Box margin="0 0 28px 0">
        <FileUpload
          accept={accept}
          dropLabel={t`Drop file here`}
          hoverLabel={description}
          onUpload={handleUpload}
        />
      </Box>
    </DialogComponent>
  )
}

export default React.memo(FileUploadDialog)

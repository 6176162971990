import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import ITermData from 'types/ITermData'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import IToolData from 'types/IToolData'
import { termGridItemStyle } from 'shared/components/termStyle'
import ErrorTerm from 'components/term/ErrorTerm'
import Term from 'components/term/Term'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'
import { IClipboard } from 'contexts/ClipboardContext'
import IStationData from 'types/IStationData'

export type TermDroppableProps = {
  station: IStationData
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function TermDroppable({
  station,
  tool,
  mutateStudy,
  clipboard,
  viewType,
}: TermDroppableProps): JSX.Element {
  const termDroppableKey = `station-${tool.station}-tool-${tool.id}-term-droppable${
    viewType === 'pin' ? '-pin' : ''
  }`

  return (
    <Grid container wrap="nowrap">
      <Droppable
        key={termDroppableKey}
        droppableId={termDroppableKey}
        direction="vertical"
        type="term"
        ignoreContainerClipping
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <Grid
            key={termDroppableKey}
            item
            xs={12}
            ref={provided.innerRef}
            sx={termGridItemStyle()}
          >
            <Paper className="MuiPaper-term">
              <Box display="flex" flexDirection="column" gap="16px" padding="24px 16px">
                {tool.terms.map((term: ITermData, index: number) =>
                  term.is_error ? (
                    <ErrorTerm key={term.id} term={term} mutateStudy={mutateStudy} />
                  ) : (
                    <Term
                      key={term.id}
                      station={station}
                      tool={tool}
                      term={term}
                      termIndex={index}
                      clipboard={clipboard}
                      viewType={viewType}
                    />
                  ),
                )}
              </Box>

              {provided.placeholder}
            </Paper>
          </Grid>
        )}
      </Droppable>
    </Grid>
  )
}

export default React.memo(TermDroppable)

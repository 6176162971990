import React, { Component } from 'react'
import Rollbar from 'rollbar'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { Box, Button, Typography } from '@mui/material'
import { t } from '@lingui/macro'
import { ErrorContextProvider } from 'contexts/ErrorContext'

type DerivedStateErrorType = {
  stack?: string
}

interface ErrorBoundaryProps {
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
  errorState: string
  children: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const rollbar = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  ? new Rollbar({
      accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
      },
    })
  : null

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: DerivedStateErrorType) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (rollbar) rollbar.error(this.props.errorState)
    this.props.enqueueSnackbar(this.props.errorState, { variant: 'error' })
  }

  handleAsyncError = (error: string) => {
    if (rollbar) rollbar.error(error)
  }

  render() {
    return (
      <ErrorContextProvider handleAsyncError={this.handleAsyncError}>
        {this.state.hasError ? (
          <Box
            display="flex"
            height="calc(100vh - 64px)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <Typography
              variant="h1"
              color="text.secondary"
              fontWeight={500}
            >{t`An error occurred`}</Typography>

            <Button
              onClick={() => window.location.reload()}
              variant="text"
              type="button"
              disableRipple
              sx={{ width: 'auto', padding: '8px 24px' }}
            >
              <Typography variant="body1">{t`Refresh the page`}</Typography>
            </Button>
          </Box>
        ) : (
          this.props.children
        )}
      </ErrorContextProvider>
    )
  }
}

import IStationData from 'types/IStationData'
import StationDataService from 'data-services/StationDataService'
import ToolDataService from 'data-services/ToolDataService'
import TermDataService from 'data-services/TermDataService'
import IToolData from '../types/IToolData'
import IStudyData, { IPasteDataInterface } from '../types/IStudyData'
import ITermData, { ITermUploadStartData, ITermUploadStartResponseData } from '../types/ITermData'
import StudyDataService from '../data-services/StudyDataService'

export default class StudyRepository {
  private static handleError: (errorMessage: string) => void = (errorMessage) => {
    console.error(errorMessage)
  }

  static initialize(handleAsyncError: (errorMessage: string) => void): void {
    StudyRepository.handleError = handleAsyncError
  }

  static updateStudy(studyId: string, studyToUpdate: IStudyData): Promise<IStudyData> {
    return new Promise<IStudyData>((resolve, reject) => {
      StudyDataService.updateStudy(studyId, studyToUpdate)
        .then(({ data: studyResponse }) => resolve(studyResponse))
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateStation(stationId: string, stationToUpdate: IStationData): Promise<IStationData> {
    return new Promise<IStationData>((resolve, reject) => {
      StationDataService.update(stationId, stationToUpdate)
        .then(({ data: stationResponse }) => resolve(stationResponse))
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateTool(toolId: string, toolToUpdate: IToolData): Promise<IToolData> {
    return new Promise<IToolData>((resolve, reject) => {
      ToolDataService.update(toolId, toolToUpdate)
        .then(({ data: toolResponse }) => resolve(toolResponse))
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateTerm(termId: string, termToUpdate: ITermData): Promise<ITermData> {
    return new Promise<ITermData>((resolve, reject) => {
      TermDataService.update(termId, termToUpdate)
        .then(({ data: termResponse }) => resolve(termResponse))
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static pasteTerms(toolId: string, data: IPasteDataInterface): Promise<IStudyData> {
    return new Promise<IStudyData>((resolve, reject) => {
      TermDataService.pasteTerms(toolId, data)
        .then(({ data: studyResponse }) => {
          resolve(studyResponse)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static fileUploadStartTerm(
    termId: string,
    data: ITermUploadStartData,
  ): Promise<ITermUploadStartResponseData> {
    return new Promise<ITermUploadStartResponseData>((resolve, reject) => {
      TermDataService.fileUploadStartTerm(termId, data)
        .then(({ data: fileUploadStartResponse }) => {
          resolve(fileUploadStartResponse)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static deleteTerm(termId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      TermDataService.deleteTerm(termId)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }
}

import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme, { colors } from 'shared/theme'

const termColors = theme.palette.term
const gridItemColors = theme.palette.gridItem

export const termGridItemStyle = (): SxProps =>
  css({
    height: '100%',
    margin: '24px 0 0 0',
    '& .MuiPaper-term': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      boxShadow: 'unset',
      background: 'transparent',
      border: `1px solid ${gridItemColors.main.border}`,
    },
  }) as SxProps

const getTermBorderStyle = (isActive: boolean, isDragging: boolean) => {
  if (isActive && !isDragging) {
    return `2px solid ${termColors.drag.border}`
  }
  if (isDragging) {
    return `1px solid ${termColors.drag.border}`
  }
  return `1px solid ${termColors.main.border}`
}
const termStyle = (isActive: boolean, isDragging = false): SxProps =>
  css({
    padding: '0',
    height: '100%',
    boxShadow: 'unset',
    background: isDragging ? termColors.drag.background : termColors.main.background,
    border: 'unset',
    borderRadius: '0',

    '& .MuiTerm-container': {
      borderRadius: '0 4px 4px 0',
      border: getTermBorderStyle(isActive, isDragging),
      borderLeft: 'unset',
    },

    '& .MuiTerm-dragHandle': {
      borderRadius: '4px 0 0 4px',
      width: '8px',
      background: colors.blue.A500,
    },
  }) as SxProps
export default termStyle

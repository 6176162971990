import React, { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  MoreHoriz as MoreHorizIcon,
  SettingsOutlined as SettingsIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { colors } from 'shared/theme'
import StationConfigDialog from 'components/dialog/StationConfigDialog'
import { serializeStation } from 'types/IStationData'

export default function ActionMenuStationRight(): JSX.Element {
  const { actionMenuContext, isContextMenuLoading } = useActionMenuContext()
  const actionButtonMoreActionRef = useRef<HTMLButtonElement>(null)
  const [actionButtonMoreActionOpen, setActionButtonMoreActionOpen] = useState(false)
  const [configStationDialogOpen, setConfigStationDialogOpen] = useState<boolean>(false)

  const handleOpenConfigStationDialog = (): void => {
    setActionButtonMoreActionOpen(false)
    setConfigStationDialogOpen(true)
  }

  const handleCloseConfigStationDialog = (): void => {
    setConfigStationDialogOpen(false)
  }

  const handleExportStationToFile = (): void => {
    setActionButtonMoreActionOpen(false)
  }

  return (
    <Box width={{ xs: '100%', mobile: 'auto' }}>
      {actionMenuContext &&
        actionMenuContext.station &&
        !actionMenuContext.toolGridItem &&
        !actionMenuContext.tool && (
          <Box display="flex" width="100%" gap={{ xs: '8px', mobile: '32px' }}>
            <ActionMenuButton
              actionMenuButtonRef={actionButtonMoreActionRef}
              actionMenuButtonAnchorOpen={actionButtonMoreActionOpen}
              setActionMenuButtonAnchorOpen={setActionButtonMoreActionOpen}
              handleActionMenuButtonClick={() =>
                setActionButtonMoreActionOpen(!actionButtonMoreActionOpen)
              }
              StartIcon={MoreHorizIcon}
              title={t`More Actions`}
              isButtonDisabled={isContextMenuLoading}
              actionMenuItems={
                <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
                  <Button
                    onClick={handleOpenConfigStationDialog}
                    variant="text"
                    type="button"
                    disableRipple
                    startIcon={<SettingsIcon sx={{ color: colors.black.A500 }} />}
                    sx={{ width: '100%', padding: '8px 24px' }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      color="text.primary"
                      textAlign="left"
                      margin="0 0 0 8px"
                    >
                      {t`Configure Station`}
                    </Typography>
                  </Button>

                  <Button
                    onClick={handleExportStationToFile}
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      serializeStation(actionMenuContext.station),
                    )}`}
                    variant="text"
                    disableRipple
                    startIcon={<DriveFolderUploadIcon sx={{ color: colors.black.A500 }} />}
                    sx={{ width: '100%', padding: '8px 24px' }}
                    download={`STS-Station (${actionMenuContext.station.title}).json`}
                    component="a"
                  >
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      color="text.primary"
                      textAlign="left"
                      margin="0 0 0 8px"
                    >
                      {t`Export Station to File`}
                    </Typography>
                  </Button>
                </Box>
              }
            />
          </Box>
        )}
      {actionMenuContext && actionMenuContext.station && (
        <StationConfigDialog
          open={configStationDialogOpen}
          onClose={handleCloseConfigStationDialog}
          station={actionMenuContext.station}
        />
      )}
    </Box>
  )
}

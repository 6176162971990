import React from 'react'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { colors } from 'shared/theme'
import orderingHelper from 'utils/orderingHelper'
import { t } from '@lingui/macro'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { KeyedMutator } from 'swr'
import IStudyData, { IStudyGridItemData } from 'types/IStudyData'
import StationDataService from 'data-services/StationDataService'
import { stationMutate } from 'hooks/studyMutations'
import StudyDataService from 'data-services/StudyDataService'
import { useErrorContext } from 'contexts/ErrorContext'
import { ReactComponent as AddAboveEdgeIcon } from 'assets/icons/add-above-edge.svg'
import { ReactComponent as AddAboveIcon } from 'assets/icons/add-above.svg'
import { ReactComponent as AddBelowEdgeIcon } from 'assets/icons/add-below-edge.svg'
import { ReactComponent as AddBelowIcon } from 'assets/icons/add-below.svg'
import { ReactComponent as AddLeftEdgeIcon } from 'assets/icons/add-left-edge.svg'
import { ReactComponent as AddLeftIcon } from 'assets/icons/add-left.svg'
import { ReactComponent as AddRightEdgeIcon } from 'assets/icons/add-right-edge.svg'
import { ReactComponent as AddRightIcon } from 'assets/icons/add-right.svg'

type ActionMenuViewSizeItemProps = {
  viewSizeKey: 'study' | 'station'
  setActionButtonViewSizeOpen: React.Dispatch<React.SetStateAction<boolean>>
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
}

export default function ActionMenuViewSizeItem({
  viewSizeKey,
  setActionButtonViewSizeOpen,
  study,
  mutateStudy,
}: ActionMenuViewSizeItemProps): JSX.Element {
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()

  const addStudyGridItem = (data: IStudyGridItemData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      StudyDataService.addStudyGridItem(study.id, data)
        .then(({ data: studyResponse }) => {
          void mutateStudy(studyResponse, false)
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const addStationGridItem = (data: IStudyGridItemData): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      StationDataService.addStationGridItem(actionMenuContext.station.id, data)
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleViewSizeChange = (
    operation: 'add' | 'remove',
    viewSizeDimension: 'row' | 'column',
    direction: 'left' | 'right' | 'top' | 'bottom',
  ) => {
    const getNoGridItemKey = (): string => {
      if (direction === 'right') {
        return `grid-item-0-${
          actionMenuContext.station ? actionMenuContext.station.column_count : study.column_count
        }`
      }

      if (direction === 'bottom') {
        return `grid-item-${
          actionMenuContext.station ? actionMenuContext.station.row_count : study.row_count
        }-0`
      }

      return 'grid-item-0-0'
    }

    const isHorizontal = direction === 'left' || direction === 'right'
    const gridItemKey =
      viewSizeKey === 'study' ? actionMenuContext.stationGridItem : actionMenuContext.toolGridItem

    const { row, column } = orderingHelper.getRowColumnByKey(gridItemKey ?? getNoGridItemKey())
    const dimensionValue = isHorizontal ? column : row
    const addFunction =
      viewSizeKey === 'study' ? orderingHelper.updateStudyStations : orderingHelper.updateStudyTools

    void mutateStudy(
      addFunction(
        study,
        dimensionValue,
        viewSizeDimension,
        direction,
        actionMenuContext,
        setActionMenuContext,
      ),
      false,
    ).then((newStudy: IStudyData) => {
      void mutateStudy(
        orderingHelper.updateStudyViewSize(
          newStudy,
          viewSizeDimension,
          viewSizeKey,
          operation,
          actionMenuContext.station,
        ),
        false,
      )
    })

    if (viewSizeKey === 'study') {
      void addStudyGridItem({
        direction,
        selected_column: column,
        selected_row: row,
      })
    }

    if (viewSizeKey === 'station') {
      void addStationGridItem({
        direction,
        selected_column: column,
        selected_row: row,
      })
    }

    setActionButtonViewSizeOpen(false)
  }

  const showEdgeIcons = (): boolean => {
    if (viewSizeKey === 'study') {
      if (actionMenuContext.stationGridItem) return true
    }
    if (viewSizeKey === 'station') {
      if (actionMenuContext.toolGridItem) return true
    }

    return false
  }

  return (
    <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
      <Button
        onClick={() => handleViewSizeChange('add', 'row', 'top')}
        variant="text"
        type="button"
        disableRipple
        startIcon={
          <SvgIcon sx={{ color: colors.black.A500 }}>
            {showEdgeIcons() ? <AddAboveEdgeIcon /> : <AddAboveIcon />}
          </SvgIcon>
        }
        sx={{ width: '100%', padding: '8px 24px' }}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          color="text.primary"
          textAlign="left"
          margin="0 0 0 8px"
        >
          {t`Above`}
        </Typography>
      </Button>

      <Button
        onClick={() => handleViewSizeChange('add', 'row', 'bottom')}
        variant="text"
        type="button"
        disableRipple
        startIcon={
          <SvgIcon sx={{ color: colors.black.A500 }}>
            {showEdgeIcons() ? <AddBelowEdgeIcon /> : <AddBelowIcon />}
          </SvgIcon>
        }
        sx={{ width: '100%', padding: '8px 24px' }}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          color="text.primary"
          textAlign="left"
          margin="0 0 0 8px"
        >
          {t`Below`}
        </Typography>
      </Button>

      <Button
        onClick={() => handleViewSizeChange('add', 'column', 'left')}
        variant="text"
        type="button"
        disableRipple
        startIcon={
          <SvgIcon sx={{ color: colors.black.A500 }}>
            {showEdgeIcons() ? <AddLeftEdgeIcon /> : <AddLeftIcon />}
          </SvgIcon>
        }
        sx={{ width: '100%', padding: '8px 24px' }}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          color="text.primary"
          textAlign="left"
          margin="0 0 0 8px"
        >
          {t`Left`}
        </Typography>
      </Button>

      <Button
        onClick={() => handleViewSizeChange('add', 'column', 'right')}
        variant="text"
        type="button"
        disableRipple
        startIcon={
          <SvgIcon sx={{ color: colors.black.A500 }}>
            {showEdgeIcons() ? <AddRightEdgeIcon /> : <AddRightIcon />}
          </SvgIcon>
        }
        sx={{ width: '100%', padding: '8px 24px' }}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          color="text.primary"
          textAlign="left"
          margin="0 0 0 8px"
        >
          {t`Right`}
        </Typography>
      </Button>
    </Box>
  )
}

import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import IStudyData, { IStudyGridItemData, IStudyImportData } from 'types/IStudyData'
import paths from 'utils/paths'

const addStudyGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.grid.studyGridItemAdd(id),
    gridItemData,
  )

const removeStudyGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.grid.studyGridItemRemove(id),
    gridItemData,
  )

const importStudyFromTemplateStudy = (
  id: string,
  gridItemData: IStudyImportData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.studiesImportStudyFromTemplate(id),
    gridItemData,
  )

const importStationFromFileStudy = (
  id: string,
  importData: IStudyImportData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.studiesImportStationFromFile(id),
    importData,
  )

const importStationFromTemplateStudy = (
  id: string,
  gridItemData: IStudyImportData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.studiesImportStationFromTemplate(id),
    gridItemData,
  )
const addStudy = (study: IStudyData): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(paths.backend.study.studies(), study)

const updateStudy = (id: string, data: IStudyData): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.put<IStudyData, AxiosResponse<IStudyData>>(paths.backend.study.studies(id), data)

const deleteStudy = (studyId: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete(paths.backend.study.studies(studyId))

const deleteStudyStations = (id: string): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.studiesDeleteStations(id),
  )

const StudyDataService = {
  addStudyGridItem,
  removeStudyGridItem,
  importStudyFromTemplateStudy,
  importStationFromFileStudy,
  importStationFromTemplateStudy,
  addStudy,
  updateStudy,
  deleteStudy,
  deleteStudyStations,
}

export default StudyDataService

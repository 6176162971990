import { Card, CardContent, Box, Typography, Grid, IconButton, CardActionArea } from '@mui/material'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import textMaxLineStyle from 'shared/textMaxLineStyle'
import styled from '@emotion/styled'
import { colors } from 'shared/theme'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import paths from 'utils/paths'
import IStudyData from 'types/IStudyData'
import StudyMoreActionMenu from 'components/study/StudyMoreActionMenu'
import { MutatorCallback } from 'swr'

type StudyCardProps = {
  studyListId: string
  study: IStudyData
  mutator: MutatorCallback
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
  setSelectedStudy: Dispatch<SetStateAction<IStudyData | null>>
}

export default function StudyCard(props: StudyCardProps) {
  const { studyListId, study, mutator, setStudyDialogOpen, setSelectedStudy } = props
  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate: NavigateFunction = useNavigate()
  const lineHeight = '1.5em'
  const numberOfLines = 2
  const maxHeight = `calc(${lineHeight} * ${numberOfLines})`
  const TypographyMaxLine = styled(Typography)`
    ${textMaxLineStyle(lineHeight, maxHeight)}
  `

  return (
    <Grid item xs={12} sm={6}>
      <Card elevation={1} sx={{ height: '100%' }}>
        <CardContent
          sx={{
            padding: '0 !important',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <Box textOverflow="ellipsis" overflow="hidden" width="100%">
            <CardActionArea
              onClick={() => navigate(paths.frontend.study(study.id))}
              sx={{
                padding: '16px 0 16px 16px',
                margin: '0',
                height: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                display: 'flex',
              }}
            >
              <Box display="flex" gap="16px">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="40px"
                  width="40px"
                  flexShrink={0}
                  sx={{ background: colors.blue.A500, borderRadius: '50%' }}
                >
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    textTransform="uppercase"
                    color={colors.lightBlue.A500}
                  >
                    {study.title && study.title !== '' ? study.title[0] : 'N'}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexGrow={1}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  <Box textOverflow="ellipsis" overflow="hidden">
                    <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
                      {study.title && study.title !== '' ? study.title : t`New study`}
                    </Typography>
                    <TypographyMaxLine
                      variant="body1"
                      paragraph
                      whiteSpace="pre-wrap"
                      sx={{ margin: 0, height: maxHeight }}
                    >
                      {study.note}
                    </TypographyMaxLine>
                  </Box>
                </Box>
              </Box>
            </CardActionArea>
          </Box>

          <Box padding="16px">
            <IconButton
              onMouseDown={(event) => event.stopPropagation()}
              onTouchStart={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation()
                setAnchorMenuOpen(!anchorMenuOpen)
              }}
              ref={anchorMenuRef}
              size="small"
              sx={{ color: colors.gray.A500, padding: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <StudyMoreActionMenu
        studyListId={studyListId}
        study={study}
        mutator={mutator}
        anchorMenuOpen={anchorMenuOpen}
        setAnchorMenuOpen={setAnchorMenuOpen}
        anchorMenuRef={anchorMenuRef}
        setSelectedStudy={setSelectedStudy}
        setStudyDialogOpen={setStudyDialogOpen}
      />
    </Grid>
  )
}

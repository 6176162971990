import { Button, Typography, Box, IconButton } from '@mui/material'
import { t } from '@lingui/macro'
import React, { useState } from 'react'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import TermDataService from 'data-services/TermDataService'
import axios from 'axios'
import {
  AddOutlined as AddIcon,
  Visibility as VisibilityIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material'
import FileUploadDialog from 'components/dialog/FileUploadDialog'
import StudyRepository from 'repositories/StudyRepository'
import { useErrorContext } from 'contexts/ErrorContext'
import { colors } from 'shared/theme'

type TermUploadFormProps = {
  mutateStudy: KeyedMutator<IStudyData>
}

export default function TermUploadForm(props: TermUploadFormProps) {
  const { mutateStudy } = props
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [isImage, setIsImage] = useState(false)
  const { handleAsyncError } = useErrorContext()
  StudyRepository.initialize(handleAsyncError)

  const handleUploadFileShowDialog = (isImage = false): void => {
    setIsImage(isImage)
    setImportDialogOpen(true)
  }

  const handleUploadFile = (file: File): void => {
    StudyRepository.fileUploadStartTerm(actionMenuContext.term.id, {
      file_name: file.name,
      file_type: file.type,
      is_image: isImage,
    }).then((data) => {
      const postData = new FormData()
      for (const key in data?.fields) {
        if (data.fields[key]) postData.append(key, data.fields[key])
      }
      postData.append('file', file)

      axios
        .post(data.url, postData)
        .then(({ data: fileResponse }) => {
          TermDataService.fileUploadFinishTerm(actionMenuContext.term.id, data.id).then(
            ({ data: termResponse }) => {
              void mutateStudy()
              setActionMenuContext((prevActionMenuContext) => ({
                ...prevActionMenuContext,
                term: termResponse,
              }))
              setImportDialogOpen(false)
            },
          )
        })
        .catch((err) => {
          handleAsyncError(err.message)
        })
    })
  }

  const getActionButton = (isImageButton: boolean, filePath?: string) => {
    const getActionButtonName = () => {
      if (filePath) return isImageButton ? t`Edit image` : t`Edit file`

      return isImageButton ? t`Add image` : t`Add file`
    }

    return (
      <Box display="flex" gap="16px" padding="4px" sx={{ border: `1px solid ${colors.gray.A700}` }}>
        {filePath && (
          <IconButton
            onClick={() => window.open(filePath, '_blank')}
            sx={{ color: colors.black.A500, mr: 0, p: 0 }}
          >
            <VisibilityIcon fontSize="small" sx={{ color: colors.black.A500 }} />
          </IconButton>
        )}

        <Button
          onClick={() => handleUploadFileShowDialog(isImageButton)}
          variant="text"
          type="button"
          disableRipple
          sx={{ width: 'auto', padding: '8px 24px' }}
          startIcon={
            filePath ? (
              <EditIcon fontSize="small" sx={{ color: colors.black.A500 }} />
            ) : (
              <AddIcon fontSize="small" sx={{ color: colors.black.A500 }} />
            )
          }
        >
          <Typography variant="body1" fontWeight={500} color="text.secondary">
            {getActionButtonName()}
          </Typography>
        </Button>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" margin="8px 0 0 0">
      <Box display="flex" gap="32px">
        {getActionButton(true, actionMenuContext.term?.image)}
        {getActionButton(false, actionMenuContext.term?.file)}
      </Box>
      <FileUploadDialog
        accept={
          isImage
            ? '.png, .jpeg, .jpg, .bmp'
            : '.pdf, .xls, .xlsx, .csv, .txt, .doc, .docx, .ppt, .pptx, .xml, .json'
        }
        maxSize={1024 * 1024 * 100}
        description={
          isImage ? t`Click or drag to import an image` : t`Click or drag to import a file`
        }
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        onUpload={handleUploadFile}
        title={t`Upload`}
        returnFile
      />
    </Box>
  )
}

import React from 'react'
import { Box, Grid, Paper } from '@mui/material'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'
import { IClipboard } from 'contexts/ClipboardContext'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'
import orderingHelper from 'utils/orderingHelper'
import { stationGridItemStyle } from 'shared/components/stationStyle'
import Station from 'components/station/Station'
import useWindowDimensions from 'utils/useWindowDimensions'

export type StationDroppableProps = {
  study: IStudyData
  stationRow: number
  stationColumn: number
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function StationDroppable({
  study,
  stationRow,
  stationColumn,
  mutateStudy,
  clipboard,
  viewType,
}: StationDroppableProps): JSX.Element {
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const { isDragging, isPinnedStationDrawerOpen, topHeaderHeight } = useStudyStatesContext()
  const { height: screenHeight } = useWindowDimensions()
  const stationDroppableKey = `station-grid-item${
    viewType === 'pin' ? '-pin' : ''
  }-${stationRow}-${stationColumn}`
  const station = orderingHelper.findStationByRowColumn(study.stations, stationRow, stationColumn)

  const isStationGridItemActive = (gridItemKey: string): boolean =>
    !!actionMenuContext &&
    actionMenuContext.stationGridItem === gridItemKey &&
    actionMenuContext.station === null

  const handleStationGridItemClick = (e: React.MouseEvent<HTMLDivElement>, gridItemKey: string) => {
    e.preventDefault()
    e.stopPropagation()

    if (station) {
      return
    }

    setActionMenuContext({
      stationGridItem: gridItemKey,
      station: null,
      toolGridItem: null,
      tool: null,
      stationListId: null,
      term: null,
    })
  }

  return (
    <Droppable
      key={stationDroppableKey}
      droppableId={stationDroppableKey}
      direction="vertical"
      type="station"
      ignoreContainerClipping
      isDropDisabled={viewType === 'pin'}
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Grid
          item
          ref={provided.innerRef}
          key={stationDroppableKey}
          sx={stationGridItemStyle(
            isDragging,
            snapshot.isDraggingOver,
            study.column_count ?? 0,
            isStationGridItemActive(stationDroppableKey),
            !!station,
            study.row_count,
            screenHeight,
            stationColumn,
            stationRow,
            isPinnedStationDrawerOpen,
            viewType,
            study.stations.filter((stationObject) => stationObject.is_pinned).length,
            topHeaderHeight,
          )}
          onClick={(e) => handleStationGridItemClick(e, stationDroppableKey)}
        >
          <Paper className="MuiPaper-station">
            {provided.placeholder}
            {(viewType === 'pin' || station) && (
              <Box display="flex" sx={{ height: isDragging ? 'auto' : '100%' }}>
                <Station
                  station={station}
                  parentRow={stationRow}
                  parentColumn={stationColumn}
                  study={study}
                  mutateStudy={mutateStudy}
                  clipboard={clipboard}
                  viewType={viewType}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      )}
    </Droppable>
  )
}

export default React.memo(StationDroppable)

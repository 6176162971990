import React from 'react'
import { Box, FormControl, MenuItem, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

type LanguageSelectorProps = {
  language: string
  onChangeLanguage: (newLanguage: string) => void
}

function LanguageSelector({ language, onChangeLanguage }: LanguageSelectorProps): JSX.Element {
  const handleSelectChange = (e: SelectChangeEvent): void => {
    e.preventDefault()
    onChangeLanguage(e.target.value)
  }

  return (
    <Box sx={{ ml: 2 }}>
      <FormControl variant="standard">
        <Select
          disableUnderline
          onChange={handleSelectChange}
          value={language}
          variant="standard"
          className="MuiSelect-text"
        >
          <MenuItem sx={{ py: '4px' }} value="en">
            <Typography variant="body1" color="text">
              English
            </Typography>
          </MenuItem>
          <MenuItem sx={{ py: '4px' }} value="fr">
            <Typography variant="body1" color="text">
              Français
            </Typography>
          </MenuItem>
          <MenuItem sx={{ py: '4px' }} value="es">
            <Typography variant="body1" color="text">
              Español
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default React.memo(LanguageSelector)

import React, { useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { useSnackbar } from 'notistack'
import { SWRConfig } from 'swr'
import { activateInitialLanguage, setInitialLanguage } from 'utils/i18n'
import axiosInstance, { axiosObserver } from 'utils/axios'
import ClipboardContext, { ClipboardState, IClipboard } from 'contexts/ClipboardContext'
import { CacheProvider } from '@emotion/react'
import STSAppBar from 'components/sts-app-bar/STSAppBar'
import ErrorBoundary from 'components/ErrorBoundary'
import createEmotionCache from 'utils/createEmotionCache'
import { DrawerComponentContextProvider } from 'contexts/DrawerComponentContext'
import { StudyStatesContextProvider } from 'contexts/StudyStatesContext'
import ReactGA from 'react-ga4'

const TRACKING_ID = 'G-915VKTNNP0'

function App(): JSX.Element {
  const [language, setLanguage] = useState<string>(activateInitialLanguage())
  const [errorState, setErrorState] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const clipboardState: IClipboard = ClipboardState()
  const clientSideEmotionCache = createEmotionCache()

  axiosObserver.errorState = setErrorState
  useEffect(() => {
    if (errorState !== '') {
      enqueueSnackbar(errorState, { variant: 'error' })
    }
  }, [errorState])

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: 'pageview', page: window.location.href, title: 'app' })
  }, [])

  const handleLanguageChange = useCallback((newLanguage: string): void => {
    setLanguage(newLanguage)
    setInitialLanguage(newLanguage)
  }, [])

  return (
    <DrawerComponentContextProvider>
      <ClipboardContext.Provider value={clipboardState}>
        <SWRConfig
          value={{
            fetcher: axiosInstance,
          }}
        >
          <ErrorBoundary enqueueSnackbar={enqueueSnackbar} errorState="errorState">
            <CacheProvider value={clientSideEmotionCache}>
              <StudyStatesContextProvider>
                <STSAppBar language={language} onChangeLanguage={handleLanguageChange} />

                <Outlet />
              </StudyStatesContextProvider>
            </CacheProvider>
          </ErrorBoundary>
        </SWRConfig>

        <CssBaseline />
      </ClipboardContext.Provider>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/40145081.js"
      />
    </DrawerComponentContextProvider>
  )
}

export default App

import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import IStudyListData from 'types/IStudyListData'
import ProjectDialog from 'components/dialog/ProjectDialog'
import ITeamData from 'types/ITeamData'
import ProjectCard from 'pages/projects/ProjectCard'

type TeamViewProps = {
  projects: IStudyListData[]
  teams: ITeamData[]
  team: ITeamData
}

export default function TeamView(props: TeamViewProps) {
  const { projects, teams, team } = props
  const [selectedStudyList, setSelectedStudyList] = useState<IStudyListData | null>(null)
  const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)

  return (
    <Grid item xs={12} sm={6} lg={4} margin="48px 0 0 0">
      <Box>
        <Grid container spacing={2}>
          {projects?.map((studyList: IStudyListData) => (
            <ProjectCard
              key={`studyList-${studyList.id}`}
              studyList={studyList}
              setSelectedStudyList={setSelectedStudyList}
              setProjectDialogOpen={setProjectDialogOpen}
              teamId={team.id}
            />
          ))}
        </Grid>
      </Box>

      {teams && (
        <ProjectDialog
          project={selectedStudyList}
          teams={teams}
          open={projectDialogOpen}
          onModalClose={() => setProjectDialogOpen(false)}
          currentTeam={team}
        />
      )}
    </Grid>
  )
}

import { Button, Typography, Alert, Box, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { t } from '@lingui/macro'
import React, { useEffect, useMemo, useState } from 'react'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import ToolDataService from 'data-services/ToolDataService'
import { toolMutate } from 'hooks/studyMutations'
import IToolData from 'types/IToolData'
import SliderComponent from '../control/SliderComponent'
import SelectComponent from '../control/SelectComponent'
import useStudyTools from '../../hooks/useStudyTools'
import usePersonalityTools from '../../hooks/usePersonalityTools'
import paths from '../../utils/paths'
import CheckBoxListComponent from '../control/CheckBoxListComponent'
import orderingHelper from '../../utils/orderingHelper'

type AIFormProps = {
  study: IStudyData | null
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
  onModalClose: () => void
}

const marks = [
  {
    value: 0.2,
    label: '⬅️Less creative',
  },
  {
    value: 0.8,
    label: 'More creative➡️',
  },
]

export default function AIForm(props: AIFormProps) {
  const { study, tool, mutateStudy, onModalClose } = props
  const [aiTemperature, setAiTemperature] = useState<number>(tool.ai_temperature)
  const [aiModel, setAiModel] = useState(tool.ai_model)
  const { data: studyTools } = useStudyTools(paths.backend.study.tools(study.id))
  const [aiJournal, setAiJournal] = useState(tool.ai_journal)
  const { data: personalityTools } = usePersonalityTools(
    paths.backend.study.tool.toolsPersonalities,
  )
  const [aiPersonality, setAiPersonality] = useState(tool.ai_personality)
  const [aiContextLists, setAiContextLists] = useState(tool.ai_context_lists)

  console.log('study tools', studyTools)

  const {
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm<IToolData>({
    defaultValues: tool ? useMemo(() => tool, [tool]) : undefined,
  })

  const handleAIFormSubmit = () => {
    trigger().then(() => {
      ToolDataService.update(tool.id, tool).then(({ data: toolResponse }) => {
        void mutateStudy(toolMutate(tool.station, tool.id, toolResponse), false)
      })
    })
  }

  const handleAiContextListsChange = (event) => {
    if (tool.ai_context_lists.indexOf(event.target.value) >= 0) {
      // ToolDataService.removeContext(tool.id, event.target.value).then(({ data: toolResponse }) => {
      //   void mutateStudy(toolMutate(tool.station, tool.id, toolResponse), false)
      // tool.ai_context_lists.filter((e) => e !== event.target.value)
      // aiContextLists.filter((e) => e !== event.target.value)
      if (tool.ai_context_lists.indexOf(event.target.value) >= 0)
        tool.ai_context_lists.splice(aiContextLists.indexOf(event.target.value), 1)
      if (aiContextLists.indexOf(event.target.value) >= 0)
        aiContextLists.splice(aiContextLists.indexOf(event.target.value), 1)
      // })
    } else {
      // ToolDataService.addContext(tool.id, event.target.value).then(({ data: toolResponse }) => {
      //   void mutateStudy(toolMutate(tool.station, tool.id, toolResponse), false)
      tool.ai_context_lists.push(event.target.value)
      aiContextLists.push(event.target.value)
      // })
    }
  }

  const handlePresetChange = (event, value) => {
    setAiModel(value)
    tool.ai_model = value
  }

  const handlePersonalityChange = (event) => {
    console.log('watch pre', tool.ai_personality)
    setAiPersonality(event.target.value)
    tool.ai_personality = event.target.value
    console.log('watch post', tool.ai_personality)
  }

  const handleJournalChange = (event) => {
    setAiJournal(event.target.value)
    tool.ai_journal = event.target.value
  }

  const handleTemperatureChange = (event, value) => {
    setAiTemperature(value)
    tool.ai_temperature = value
  }

  const onLocalModalClose = () => {
    handleAIFormSubmit()
    onModalClose()
  }

  useEffect(() => {
    if (study) {
      watch((formValue) => {
        tool.ai_personality = formValue.ai_personality
        tool.ai_journal = formValue.ai_journal
      })
    }
  }, [watch, study?.id])

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography color="text" variant="h1" fontWeight={400}>
        {t`Configure your AI Colleague`}
      </Typography>

      <FormContainer>
        {errors.root?.serverError && (
          <Box margin="0 0 15px 0">
            <Alert severity="error">{errors.root?.serverError.message}</Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" gutterBottom>
            Choose what content to share with the ai.
          </Typography>
          <CheckBoxListComponent
            control={control as never}
            context={aiContextLists}
            errors={errors as never}
            options={
              studyTools
                ? (studyTools
                    .map((studyTool) => ({
                      name: studyTool.title,
                      id: studyTool.id,
                    }))
                    .filter((x) => x.name)
                    .sort((a, b) =>
                      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                    ) as never)
                : []
            }
            sx={{
              background: 'white',
              width: '100%',
              display: 'flex',
              flexFlow: 'column wrap',
              gap: '0 10px',
              maxHeight: 300,
              overflow: 'auto',
            }}
            name="ai_context_lists"
            onChange={handleAiContextListsChange}
          />

          <Box width="100%" sx={{ background: 'transparent', py: 2 }}>
            <SelectComponent
              control={control as never}
              errors={errors as never}
              options={
                personalityTools
                  ? (personalityTools
                      .map((personalityTool) => ({
                        name: personalityTool.title,
                        id: personalityTool.id,
                      }))
                      .filter((x) => x.name)
                      .sort((a, b) => orderingHelper.sortByLanguage(a.name, b.name)) as never)
                  : []
              }
              sx={{
                background: 'white',
                width: '100%',
              }}
              label={t`Enhance your colleagues responses by giving it a personality`}
              name="ai_personality"
              includeBlankOption
              // onChange={handlePersonalityChange}
            />
          </Box>

          <SelectComponent
            control={control as never}
            errors={errors as never}
            options={
              studyTools
                ? (studyTools
                    .map((studyTool) => ({
                      name: studyTool.title,
                      id: studyTool.id,
                    }))
                    .filter((x) => x.name)
                    .sort((a, b) =>
                      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                    ) as never)
                : []
            }
            sx={{ background: 'white', width: '100%' }}
            label={t`Select a list where to keep a copy of the answers (optional)`}
            name="ai_journal"
            includeBlankOption
            onChange={handleJournalChange}
          />

          <Box display="flex" justifyContent="flex-end" margin="48px 0 16px 0" gap="8px">
            <Button
              variant="contained"
              type="button"
              sx={{ width: 'auto' }}
              onClick={() => onLocalModalClose()}
            >
              {t`Done`}
            </Button>
          </Box>
        </Box>
      </FormContainer>
    </Box>
  )
}

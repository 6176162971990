export const paths = {
  frontend: {
    home: `/`,
    projects: `/projects/`,
    study: (key?: string) => (key ? `/study/${key}/` : `/study/`),
    studyList: (id: string) => `/projects/${id}/`,
    teams: (key?: string) => (key ? `/study/teams/${key}/` : `/study/teams/`),
    teamsSetting: (key: string) => `/study/teams/${key}/setting/`,
    teamsAdd: `/study/teams/add/`,
  },
  backend: {
    studyList: {
      studyLists: (id?: string) => (id ? `/studies/study-lists/${id}/` : `/studies/study-lists/`),
      studyListsImportStudyFromTemplate: (id: string) =>
        `/studies/study-lists/${id}/studies/import-from-template/`,
    },

    study: {
      studies: (id?: string) => (id ? `/studies/studies/${id}/` : `/studies/studies/`),
      studiesDeleteStations: (id: string) => `/studies/studies/${id}/stations/delete/`,
      studiesImportStudyFromTemplate: (id: string) =>
        `/studies/studies/${id}/import-from-template/`,
      studiesImportStationFromTemplate: (id: string) =>
        `/studies/studies/${id}/stations/import-from-template/`,
      studiesImportStationFromFile: (id: string) =>
        `/studies/studies/${id}/stations/import-from-file/`,
      studiesPasteStation: (id: string) => `/studies/studies/${id}/stations/paste/`,
      teams: (id?: string) => (id ? `/studies/teams/${id}/` : `/studies/teams/`),
      teamsRemoveMembers: (id: string) => `/studies/teams/${id}/remove-members/`,
      teamsAddMembers: (id: string) => `/studies/teams/${id}/add-members/`,
      studiesTemplates: `/studies/studies/templates/`,
      tools: (id: string) => `/studies/studies/${id}/tools/`,

      station: {
        stations: (id?: string) => (id ? `/studies/stations/${id}/` : `/studies/stations/`),
        stationsMove: (id: string) => `/studies/stations/${id}/move/`,
        stationsPush: (id: string) => `/studies/stations/${id}/push/`,
        stationsDeleteTools: (id: string) => `/studies/stations/${id}/tools/delete/`,
        stationsImportToolFromTemplate: (id: string) =>
          `/studies/stations/${id}/tools/import-from-template/`,
        stationsImportToolFromFile: (id: string) =>
          `/studies/stations/${id}/tools/import-from-file/`,
        stationsPasteTool: (id: string) => `/studies/stations/${id}/tools/paste/`,
        stationsTemplates: `/studies/stations/templates/`,
      },

      tool: {
        tools: (id?: string) => (id ? `/studies/tools/${id}/` : `/studies/tools/`),
        toolsMove: (id: string) => `/studies/tools/${id}/move/`,
        toolsPush: (id: string) => `/studies/tools/${id}/push/`,
        toolsProcess: (id: string) => `/studies/tools/${id}/process/`,
        toolsDeleteTerms: (id: string) => `/studies/tools/${id}/terms/delete/`,
        toolsPasteTerm: (id: string) => `/studies/tools/${id}/terms/paste/`,
        toolsImportTermFromFile: (id: string) => `/studies/tools/${id}/terms/import-from-file/`,
        toolsTemplates: `/studies/tools/templates/`,
        toolsPersonalities: `/studies/tools/personalities`,
      },

      term: {
        terms: (id?: string) => (id ? `/studies/terms/${id}/` : `/studies/terms/`),
        termsMove: (id: string) => `/studies/terms/${id}/move/`,
        termsFileUploadStart: (id: string) => `/studies/terms/${id}/file-upload/start/`,
        termsFileUploadFinish: (id: string, fileId: string) =>
          `/studies/terms/${id}/file-upload/${fileId}/finish/`,
        termsSpeechToText: `/studies/terms/speech-to-text/`,
      },

      grid: {
        studyGridItemAdd: (id: string) => `/studies/studies/${id}/grid/add/`,
        studyGridItemRemove: (id: string) => `/studies/studies/${id}/grid/remove/`,
        stationGridItemAdd: (id: string) => `/studies/stations/${id}/grid/add/`,
        stationGridItemRemove: (id: string) => `/studies/stations/${id}/grid/remove/`,
      },
    },
  },
}

export function buildQueryString(endpoint: string, filters: StudyRouteFilter[]) {
  const queryParams = filters.map((filter) => `${filter.type}=${filter.value}`)
  return queryParams.length > 0 ? `${endpoint}?${queryParams.join('&')}` : endpoint
}

interface StudyRouteFilter {
  type: 'study_id'
  value: string
}

export default paths

import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import paths from 'utils/paths'
import ITeamData, { TeamAddMemberType, TeamRemoveMemberType } from 'types/ITeamData'

const addTeam = (team: ITeamData): Promise<AxiosResponse<ITeamData>> =>
  axiosInstance.post<ITeamData, AxiosResponse<ITeamData>>(`${paths.backend.study.teams()}`, team)

const updateTeam = (id: string, data: ITeamData): Promise<AxiosResponse<ITeamData>> =>
  axiosInstance.patch<ITeamData, AxiosResponse<ITeamData>>(`${paths.backend.study.teams(id)}`, data)

const removeTeamMembers = (
  id: string,
  data: TeamRemoveMemberType,
): Promise<AxiosResponse<unknown>> =>
  axiosInstance.post<TeamRemoveMemberType, AxiosResponse<unknown>>(
    `${paths.backend.study.teamsRemoveMembers(id)}`,
    data,
  )

const addTeamMembers = (id: string, data: TeamAddMemberType): Promise<AxiosResponse<unknown>> =>
  axiosInstance.post<TeamAddMemberType, AxiosResponse<unknown>>(
    `${paths.backend.study.teamsAddMembers(id)}`,
    data,
  )

const TeamDataService = {
  addTeam,
  updateTeam,
  removeTeamMembers,
  addTeamMembers,
}

export default TeamDataService

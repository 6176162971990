import { styled } from '@mui/material/styles'
import { SvgIconProps } from '@mui/material'
import {
  ArrowDownward as ArrowDownwardIcon,
  ExpandMore as ExpandMoreIcon,
  PushPinOutlined as PushPinOutlinedIcon,
  ExpandCircleDownOutlined as ExpandCircleDownIcon,
} from '@mui/icons-material'

export type RotationalIconProps = SvgIconProps & {
  hasContent?: boolean
  degrees?: number
}

export const RotationalArrowDownwardIcon = styled(
  ({ degrees = 0, hasContent, ...others }: RotationalIconProps): JSX.Element => (
    <ArrowDownwardIcon {...others} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'degrees',
  },
)(({ theme, degrees }) => ({
  transform: `rotate(${degrees}deg)`,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const RotationalExpandMoreIcon = styled(
  ({ degrees = 0, hasContent, ...others }: RotationalIconProps): JSX.Element =>
    hasContent ? <ExpandCircleDownIcon {...others} /> : <ExpandMoreIcon {...others} />,
  {
    shouldForwardProp: (prop) => prop !== 'degrees',
  },
)(({ theme, degrees }) => ({
  transform: `rotate(${degrees}deg)`,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const RotationalPushPinOutlinedIcon = styled(
  ({ degrees = 0, hasContent, ...others }: RotationalIconProps): JSX.Element => (
    <PushPinOutlinedIcon {...others} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'degrees',
  },
)(({ theme, degrees }) => ({
  transform: `rotate(${degrees}deg)`,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

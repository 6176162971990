import React from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Box,
} from '@mui/material'
import { colors } from '../../shared/theme'

export type CheckboxProps = MuiCheckboxProps & {
  label?: string | number | React.ReactElement
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  labelSx?: SxProps<Theme>
}

function FormCheckbox({
  label,
  labelPlacement = 'end',
  labelSx = {},
  ...others
}: CheckboxProps): JSX.Element {
  return (
    <Box>
      {label && (
        <FormControl>
          <FormControlLabel
            control={<MuiCheckbox {...others} />}
            label={label}
            labelPlacement={labelPlacement}
            sx={labelSx}
          />
        </FormControl>
      )}
      {!label && (
        <MuiCheckbox
          {...others}
          sx={{
            margin: 0,
            padding: 0,
            color: colors.lightBlue.A600,
            '&.Mui-checked': {
              color: colors.blue.A500,
            },
          }}
        />
      )}
    </Box>
  )
}

export default FormCheckbox

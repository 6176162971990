import React, { useRef, useState } from 'react'
import { Box, Typography, Container, IconButton, Tooltip, Grid, Chip } from '@mui/material'
import useStudyLists from 'hooks/useStudyLists'
import { t } from '@lingui/macro'
import ProjectMenu from 'components/SideMenuDrawer'
import ProjectDialog from 'components/dialog/ProjectDialog'
import { colors } from 'shared/theme'
import AddImportButton from 'components/AddImportButton'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import folderImage from 'assets/folder.svg'
import { ArrowBack as ArrowBackIcon, ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import useStudyList from 'hooks/useStudyList'
import StudyCard from 'components/study/StudyCard'
import IStudyData from 'types/IStudyData'
import StudyDialog from 'components/dialog/StudyDialog'
import ProjectMoreActionMenu from 'pages/projects/ProjectMoreActionMenu'
import ProgressBackdrop from 'components/control/ProgressBackdrop'
import useTeams from 'hooks/useTeams'
import paths from 'utils/paths'
import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'

function Project(): JSX.Element {
  const [projectDialogOpen, setProjectDialogOpen] = useState<boolean>(false)
  const [studyDialogOpen, setStudyDialogOpen] = useState<boolean>(false)
  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [selectedStudy, setSelectedStudy] = useState<IStudyData | null>(null)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate: NavigateFunction = useNavigate()
  const { studyLists } = useStudyLists()
  const { teams } = useTeams()
  const { id } = useParams()
  const { studyList, mutateStudyList } = useStudyList(id)

  const handleAddProjectClick = () => {
    setStudyDialogOpen(true)
    setSelectedStudy(null)
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ padding: { xs: '0 24px 0 24px', md: '0 24px 0 0' } }}
    >
      <Box display="flex">
        {/* LEFT SECTION */}
        <ProjectMenu studyLists={studyLists?.data} teams={teams?.data} />

        {/* RIGHT SECTION */}
        <Box sx={sideMenuDrawerRightBoxStyle()}>
          <Box display="flex" flexDirection="column">
            {/* TOP BAR */}
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="flex-start"
              gap={{ xs: '24px', md: '0' }}
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              <Box
                display="flex"
                sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <Box display="flex" alignItems="flex-start" width="40px" margin="4px 0 0 0">
                  <Tooltip title={t`Back`} disableInteractive>
                    <IconButton color="inherit" onClick={() => navigate('/')}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  <Box display="flex">
                    <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                      {studyList?.data?.title && studyList?.data?.title !== ''
                        ? studyList?.data?.title
                        : t`New project`}
                    </Typography>

                    <Box display="flex" alignItems="center">
                      <IconButton
                        color="inherit"
                        onClick={() => setAnchorMenuOpen(!anchorMenuOpen)}
                        ref={anchorMenuRef}
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <Typography variant="body1" fontWeight={400}>
                        {studyList?.data?.note}
                      </Typography>
                    </Box>

                    {studyList?.data?.team.id && (
                      <Chip
                        onClick={() => navigate(paths.frontend.teams(studyList?.data?.team.id))}
                        label={
                          <Typography variant="body1" color="text" fontWeight={450}>
                            {studyList?.data?.team.name}
                          </Typography>
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <AddImportButton
                addButtonText={t`Create new study`}
                handleAddClick={handleAddProjectClick}
              />
            </Box>

            {/* BODY */}

            {!submitting && studyList && studyList?.data?.studies.length >= 0 && (
              <Box margin="24px 0 0 0">
                <Grid container spacing={2}>
                  {studyList?.data?.studies.map((study: IStudyData) => (
                    <StudyCard
                      key={`study-${study.id}`}
                      studyListId={id}
                      study={study}
                      mutator={mutateStudyList}
                      setStudyDialogOpen={setStudyDialogOpen}
                      setSelectedStudy={setSelectedStudy}
                    />
                  ))}
                </Grid>
              </Box>
            )}

            {submitting && <ProgressBackdrop open={submitting} margin="0 0 0 15% " />}

            {studyList && studyList?.data?.studies.length === 0 && (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexGrow={1}
                margin={{ xs: '30% 0 0 0', md: '15% 0 0 0' }}
                flexDirection="column"
              >
                <Box
                  component="img"
                  sx={{
                    height: 'auto',
                    width: 'auto',
                  }}
                  src={folderImage}
                />

                <Box display="flex" flexDirection="column" gap="32px" margin="48px 0 0 0">
                  <Typography textAlign="center">{t`This project doesn’t have any studies.`}</Typography>
                  <Typography textAlign="center">{t`Create a new study or import from your files or from a template.`}</Typography>
                </Box>
              </Box>
            )}

            {studyList && (
              <Box>
                <ProjectMoreActionMenu
                  studyList={studyList.data}
                  anchorMenuOpen={anchorMenuOpen}
                  setAnchorMenuOpen={setAnchorMenuOpen}
                  anchorMenuRef={anchorMenuRef}
                  setProjectDialogOpen={setProjectDialogOpen}
                  redirectAfterDelete
                />
                <ProjectDialog
                  project={studyList.data}
                  teams={teams ? teams.data : null}
                  open={projectDialogOpen}
                  onModalClose={() => setProjectDialogOpen(false)}
                />

                <StudyDialog
                  projectId={studyList?.data.id}
                  study={selectedStudy}
                  open={studyDialogOpen}
                  onModalClose={() => setStudyDialogOpen(false)}
                  mutator={mutateStudyList}
                  setSubmitting={setSubmitting}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(Project)

import React, { createContext, useState, useContext, useMemo } from 'react'

interface studyStatesContextType {
  isDragging: boolean
  isInfiniteLoading: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  setIsInfiniteLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  pasteMenuCount: number
  setPasteMenuCount: React.Dispatch<React.SetStateAction<number>>
  isPinnedStationDrawerOpen: boolean
  setPinnedStationDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  topHeaderHeight: number
  setTopHeaderHeight: React.Dispatch<React.SetStateAction<number>>
  infiniteScrollKey: string
  setInfiniteScrollKey: React.Dispatch<React.SetStateAction<string>>
}

const studyStatesContext = createContext<studyStatesContextType | undefined>(undefined)

export const useStudyStatesContext = () => {
  const context = useContext(studyStatesContext)
  if (!context) {
    throw new Error('useContextStudyEventStates must be used within a StudyStatesContextProvider')
  }
  return context
}

export const StudyStatesContextProvider: React.FC = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pasteMenuCount, setPasteMenuCount] = useState(0)
  const [isPinnedStationDrawerOpen, setPinnedStationDrawerOpen] = useState(false)
  const [topHeaderHeight, setTopHeaderHeight] = useState(0)
  const [infiniteScrollKey, setInfiniteScrollKey] = useState('infinite-scroll-grid')

  const value = useMemo(
    () => ({
      isDragging,
      setIsDragging,
      isInfiniteLoading,
      setIsInfiniteLoading,
      isLoading,
      setIsLoading,
      pasteMenuCount,
      setPasteMenuCount,
      isPinnedStationDrawerOpen,
      setPinnedStationDrawerOpen,
      topHeaderHeight,
      setTopHeaderHeight,
      infiniteScrollKey,
      setInfiniteScrollKey,
    }),
    [
      isDragging,
      isInfiniteLoading,
      isLoading,
      pasteMenuCount,
      isPinnedStationDrawerOpen,
      topHeaderHeight,
      infiniteScrollKey,
    ],
  )

  return <studyStatesContext.Provider value={value}>{children}</studyStatesContext.Provider>
}

import { Button, Alert, Box, Typography, IconButton, Tooltip } from '@mui/material'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import TextFieldComponent from 'components/control/TextFieldComponent'
import { FormContainer } from 'react-hook-form-mui'
import { t } from '@lingui/macro'
import React, { useEffect, useMemo, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import paths from 'utils/paths'
import useTeams, { useTeam } from 'hooks/useTeams'
import ITeamData, { TeamAddMemberType, TeamRemoveMemberType } from 'types/ITeamData'
import TeamDataService from 'data-services/TeamDataService'
import { AddOutlined as AddIcon, RemoveCircle as RemoveCircleIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { colors } from 'shared/theme'
import IUserData from 'types/IUserData'
import AccountDataService from 'data-services/AccountDataService'
import { Trans } from '@lingui/react'
import ConfirmDialog from 'components/control/ConfirmDialog'

type TeamFormProps = {
  team?: ITeamData | null
}

type DefaultMemberEmailInputsType = {
  id: string
  email: string
}

const defaultMemberEmailInputs: DefaultMemberEmailInputsType[] = [
  {
    id: '0',
    email: '',
  },
]

export default function TeamForm(props: TeamFormProps) {
  const { team } = props
  const { mutateTeams } = useTeams()
  const { mutateTeam } = useTeam(team?.id)
  const navigate: NavigateFunction = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [memberEmailInputs, setMemberEmailInputs] = useState<DefaultMemberEmailInputsType[] | []>(
    team ? [] : defaultMemberEmailInputs,
  )
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [user, setUser] = useState<IUserData | null>(null)
  const [userToRemove, setUserToRemove] = useState<IUserData | null>(null)
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ITeamData>({
    defaultValues: useMemo(() => team, [team]),
  })

  useEffect(() => {
    if (team) {
      AccountDataService.getCurrentUserInfo().then((response) => {
        const userResponse: IUserData | null = response.data
        setUser(userResponse)
      })
    }
  }, [team])

  function formatMemberEmailInputs(data: ITeamData): DefaultMemberEmailInputsType[] {
    return Object.keys(data)
      .filter((key) => key.includes('email'))
      .map((key) => {
        const number = key.replace('email_', '')
        return {
          id: data[`id_${number}`],
          email: data[`email_${number}`],
        }
      })
  }

  function resetMemberEmailInputs(data: ITeamData): void {
    Object.keys(data)
      .filter((key) => key.includes('email'))
      .forEach((key) => {
        setValue(key as 'member_emails' | `users.${number}.email`, '')
      })
  }

  const handleAddTeam = (data: ITeamData) => TeamDataService.addTeam(data)

  const handleEditTeam = (data: ITeamData) => TeamDataService.updateTeam(data.id, data)

  const handleAddTeamMember = (teamId: string, newMembers: string[]) => {
    const body = { new_members: newMembers } as TeamAddMemberType
    return TeamDataService.addTeamMembers(teamId, body)
  }

  const handleRemoveTeamMember = (userToRemove: IUserData) => {
    setUserToRemove(userToRemove)
    setDeleteDialogOpen(true)
  }

  const handleRemoveTeamMemberConfirm = () => {
    if (team && userToRemove) {
      const body = { members_to_remove: [userToRemove.id] } as TeamRemoveMemberType
      TeamDataService.removeTeamMembers(team.id, body).then(() => {
        void mutateTeam()
        setDeleteDialogOpen(false)
        enqueueSnackbar(`Member "${userToRemove.name}" successfully removed`, {
          variant: 'success',
        })
      })
    }
  }

  const handleTeamFormErrors = (err: AxiosError) => {
    if (err.response?.status !== 404 && err.response?.data) {
      Object.keys(err.response.data).forEach((key) => {
        if (key === 'new_members') {
          Object.keys(err.response.data[key]).forEach((responseKey) => {
            setError(`email_${responseKey.toString()}` as `users.${number}.email`, {
              message: err.response.data[key][responseKey],
            })
          })
        } else {
          setError('root.serverError', {
            message: err.response.data[key],
          })
        }
      })
    } else {
      setError('root.serverError', {
        message: t`Unexpected result, please try again.`,
      })
    }
  }

  const handleTeamFormSubmit = (data: ITeamData) => {
    const memberEmailInputs = formatMemberEmailInputs(data)
    const newMembers = memberEmailInputs
      .filter((emailInput) => emailInput.email && emailInput.email !== '')
      .map((emailInput) => emailInput.email)
    data.new_members = []

    let responsePromise: Promise<AxiosResponse<ITeamData>>
    if (team) responsePromise = handleEditTeam(data)
    else {
      responsePromise = handleAddTeam(data)
    }

    responsePromise
      .then((response: AxiosResponse<ITeamData>) => {
        if (response.data)
          handleAddTeamMember(response.data.id, newMembers)
            .then(() => {
              void mutateTeams()
              void mutateTeam()
              setMemberEmailInputs([])
              resetMemberEmailInputs(data)
              if (team)
                enqueueSnackbar(t`Team "${data.name}" successfully updated`, {
                  variant: 'success',
                })
              else navigate(paths.frontend.teams(response.data.id))
            })
            .catch((err: AxiosError) => {
              handleTeamFormErrors(err)
            })
      })
      .catch((err: AxiosError) => {
        handleTeamFormErrors(err)
      })
  }

  const handleAddMemberEmailInput = () => {
    setMemberEmailInputs((prevInputs) => [
      ...prevInputs,
      {
        id: prevInputs.length.toString(),
        name: '',
        email: '',
      } as DefaultMemberEmailInputsType,
    ])
  }

  const getDeleteDialogMessage = () => (
    <Trans
      id="deleteTeamMemberDialogMessage"
      components={{
        main: <Typography variant="h5" textAlign="left" />,
        bold: <Typography component="span" variant="h5" textAlign="left" fontWeight={500} />,
      }}
      values={{ 0: userToRemove?.name, 1: team?.name }}
    />
  )

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <FormContainer
        onSuccess={
          handleSubmit((e) => handleTeamFormSubmit(e)) as unknown as SubmitHandler<FieldValues>
        }
      >
        {errors.root?.serverError && (
          <Box margin="0 0 24px 0">
            <Alert severity="error">{errors.root?.serverError.message}</Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap="32px">
          <Box display="flex">
            <Box sx={{ width: '30%' }}>
              <Typography variant="h5" color="text.secondary" fontWeight={500}>
                {t`Name`}
              </Typography>
            </Box>

            <TextFieldComponent
              control={control as never}
              name="name"
              type="text"
              label={t`Team name`}
              errors={errors}
              requiredMsg={t`Team name is required`}
              autoFocus
              sx={{ background: 'white', width: '100%' }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex">
              <Box sx={{ width: '30%' }}>
                <Typography variant="h5" color="text.secondary" fontWeight={500}>
                  {t`Members`}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" sx={{ width: '100%' }} gap="24px">
                {team &&
                  team.users.map((teamUser) => (
                    <Box display="flex" justifyContent="space-between" key={`name-${teamUser.id}`}>
                      <Typography variant="h5" color="text">
                        {teamUser.name}
                      </Typography>
                      {user && user.id !== teamUser.id && (
                        <Tooltip title={t`Remove team member`} disableInteractive>
                          <IconButton
                            onClick={() => handleRemoveTeamMember(teamUser)}
                            sx={{ color: colors.red.A500, padding: 0 }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  ))}
                {memberEmailInputs.map((memberEmailInput: DefaultMemberEmailInputsType) => (
                  <TextFieldComponent
                    key={`email_${memberEmailInput.id}`}
                    control={control as never}
                    name={`email_${memberEmailInput.id}`}
                    type="text"
                    label={t`Email`}
                    errors={errors}
                    sx={{ background: 'white', width: '100%' }}
                  />
                ))}
                <Button
                  onClick={handleAddMemberEmailInput}
                  variant="text"
                  type="button"
                  disableRipple
                  sx={{ width: 'auto', padding: '8px 24px' }}
                  startIcon={<AddIcon />}
                >
                  <Typography variant="body1" fontWeight={500} color="primary">
                    {t`Add another member`}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" margin="16px 0" gap="16px">
            <Button
              variant="containedWhite"
              type="button"
              sx={{ width: 'auto', minWidth: '125px' }}
              onClick={() => navigate(-1)}
            >
              {t`Cancel`}
            </Button>

            <Button variant="contained" type="submit" sx={{ width: 'auto', minWidth: '125px' }}>
              {team ? t`Save changes` : t`Create team`}
            </Button>
          </Box>
        </Box>
      </FormContainer>
      <ConfirmDialog
        confirmText={t`Remove`}
        description={getDeleteDialogMessage() as unknown as string}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleRemoveTeamMemberConfirm}
        title={t`Remove team member`}
        confirmButtonVariant="containedRed"
        cancelButtonVariant="containedWhite"
      />
    </Box>
  )
}

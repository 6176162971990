import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme from '../theme'

const pinnedStationDrawerStyle = (
  isPinnedStationDrawerOpen: boolean,
  screenHeight: number,
  topHeaderHeight: number,
): SxProps =>
  css({
    display: 'flex',
    flexDirection: 'column',
    overflow: isPinnedStationDrawerOpen ? 'scroll' : 'unset',
    width: isPinnedStationDrawerOpen ? '50%' : '0',
    maxWidth: isPinnedStationDrawerOpen ? '50%' : '0',
    height: isPinnedStationDrawerOpen ? '100%' : '0',
    '& .MuiDrawer-pinnedStations': {
      height: isPinnedStationDrawerOpen
        ? `calc(${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight} - 40px)`
        : 0,
      '& > .MuiPaper-root': {
        overflow: isPinnedStationDrawerOpen ? 'unset' : 'auto',
        border: 'unset',
      },
    },
  }) as SxProps
export default pinnedStationDrawerStyle

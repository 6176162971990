import { css } from '@emotion/react'

const textMaxLineStyle = (lineHeight: string, maxHeight: string) =>
  css({
    lineHeight,
    maxHeight,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
  })

export default textMaxLineStyle

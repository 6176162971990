import { FormProvider, UseFormReturn } from 'react-hook-form'
import React, { useEffect } from 'react'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import { debounce } from 'lodash'
import IStationData from 'types/IStationData'
import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'
import { useErrorContext } from 'contexts/ErrorContext'
import StudyRepository from 'repositories/StudyRepository'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'
import orderingHelper from 'utils/orderingHelper'
import { stationMutate } from 'hooks/studyMutations'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { useActionMenuContext } from 'contexts/ActionMenuContext'

type StudyDataFormProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  studyForm: UseFormReturn
  children: React.ReactNode
  pinnedStations: IStationData[]
}

export type FormStudyData = {
  study?: IStudyData
  stations?: { [key: string]: IStationData }
  tools?: { [key: string]: IToolData }
  terms?: { [key: string]: ITermData }
}

const studyFieldsKeys = ['title', 'note']
const stationFieldsKey = ['title', 'note']
const toolFieldsKey = ['title', 'subtitle', 'note']
const termFieldsKey = ['term', 'note']

export default function StudyDataForm(props: StudyDataFormProps) {
  const { study, mutateStudy, studyForm, pinnedStations, children } = props
  const { setPinnedStationDrawerOpen, setInfiniteScrollKey } = useStudyStatesContext()
  const { handleAsyncError } = useErrorContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))
  const { setActionMenuContext } = useActionMenuContext()

  StudyRepository.initialize(handleAsyncError)

  useEffect(() => {
    if (pinnedStations.length > 0) {
      if (!orderingHelper.validatePinnedStationsOrdering(pinnedStations)) {
        const fixedPinnedStations = orderingHelper.getPinnedStationsOrdering(pinnedStations)
        fixedPinnedStations.forEach((fixedPinnedStation) => {
          StudyRepository.updateStation(fixedPinnedStation.id, {
            pin_order: fixedPinnedStation.pin_order,
          } as IStationData).then((stationResponse) => {
            void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          })
        })
      }
    }
    setInfiniteScrollKey(pinnedStations.length > 0 ? 'infinite-scroll-pin' : 'infinite-scroll-grid')
    setPinnedStationDrawerOpen(!isMobile && pinnedStations.length > 0)
  }, [study?.id, isMobile])

  useEffect(() => {
    if (study) {
      const { dirtyFields } = studyForm.formState
      if (Object.keys(dirtyFields).length === 0) {
        const transformedStations = study.stations.reduce((acc, station) => {
          acc[station.id] = Object.keys(station)
            .filter((key) => stationFieldsKey.includes(key))
            .reduce((obj, key) => {
              obj[key] = station[key]
              return obj
            }, {})
          return acc
        }, {})

        const transformedTools = study.stations
          .flatMap((station) => station.tools || [])
          .reduce((acc, tool) => {
            const toolId = tool.id
            acc[toolId] = Object.keys(tool)
              .filter((key) => toolFieldsKey.includes(key))
              .reduce((toolAcc, key) => {
                toolAcc[key] = tool[key]
                return toolAcc
              }, {})

            return acc
          }, {})

        const transformedTerms = study.stations
          .flatMap((station) => station.tools || [])
          .flatMap((tool) => tool.terms || [])
          .reduce((acc, term) => {
            const termId = term.id
            acc[termId] = Object.keys(term)
              .filter((key) => termFieldsKey.includes(key))
              .reduce((termAcc, key) => {
                termAcc[key] = term[key]
                return termAcc
              }, {})

            return acc
          }, {})

        studyForm.reset({
          study: Object.keys(study)
            .filter((key) => studyFieldsKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = study[key]
              return obj
            }, {}),
          stations: transformedStations,
          tools: transformedTools,
          terms: transformedTerms,
        })
      }
    }
  }, [study])

  const handleFormStudySubmitContext = (
    objectID: string,
    data: IStationData | IToolData | ITermData,
  ) => {
    setActionMenuContext((prevState) => {
      const updatedState = { ...prevState }

      Object.keys(updatedState).forEach((key) => {
        if (updatedState[key] && updatedState[key].id === objectID) {
          updatedState[key] = { ...updatedState[key], ...data }
        }
      })

      return updatedState
    })
  }

  const handleFormStudySubmit = (data: FormStudyData) => {
    const { dirtyFields } = studyForm.formState

    if (dirtyFields && dirtyFields.study) {
      void StudyRepository.updateStudy(study.id, data.study as IStudyData)
    }

    if (dirtyFields && dirtyFields.stations) {
      Object.keys(dirtyFields.stations).forEach((stationId) => {
        const stationData = data.stations[stationId]
        handleFormStudySubmitContext(stationId, stationData)
        void StudyRepository.updateStation(stationId, stationData)
      })
    }

    if (dirtyFields && dirtyFields.tools) {
      Object.keys(dirtyFields.tools).forEach((toolId) => {
        const toolData = data.tools[toolId]
        handleFormStudySubmitContext(toolId, toolData)
        void StudyRepository.updateTool(toolId, toolData)
      })
    }

    if (dirtyFields && dirtyFields.terms) {
      Object.keys(dirtyFields.terms).forEach((termId) => {
        const termData = data.terms[termId]
        handleFormStudySubmitContext(termId, termData)
        void StudyRepository.updateTerm(termId, termData)
      })
    }

    void mutateStudy().then(() => {
      studyForm.reset({}, { keepValues: true })
    })
  }

  useEffect(() => {
    if (study) {
      const debouncedCb = debounce((formValue) => handleFormStudySubmit(formValue), 500)
      studyForm.watch(debouncedCb)
    }
  }, [studyForm.watch, study?.id])

  return <FormProvider {...studyForm}>{children}</FormProvider>
}

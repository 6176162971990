import React, { createContext, useContext, useMemo } from 'react'

const defaultHandleAsyncError = (error: string) => {
  console.error('Error caught in ErrorContext: ', error)
}

const errorContext = createContext({
  handleAsyncError: defaultHandleAsyncError,
})

export const useErrorContext = () => {
  const context = useContext(errorContext)
  if (!context) {
    throw new Error('useErrorContext must be used within a ErrorContextProvider')
  }
  return context
}

export const ErrorContextProvider: React.FC<{ handleAsyncError: (error: string) => void }> = ({
  children,
  handleAsyncError,
}) => {
  const value = useMemo(() => ({ handleAsyncError }), [handleAsyncError])

  return <errorContext.Provider value={value}>{children}</errorContext.Provider>
}

import useSWR from 'swr'
import { SWRResponse } from 'swr/_internal'
import IToolListData from 'types/IToolData'
import axiosInstance from 'utils/axios'

function useStudyTools(path: string): SWRResponse<IToolListData[]> {
  return useSWR<IToolListData[]>(path, (endpoint: string) =>
    axiosInstance.get(endpoint).then((data) => data?.data),
  )
}

export default useStudyTools

import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme, { colors } from 'shared/theme'

const headerStyle = (): SxProps =>
  css({
    background: 'white',
    width: '100%',
    zIndex: '1',

    [theme.mediaQueryMin.md]: {
      position: 'fixed',
    },

    '& .MuiBox-titleContainer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '4px 24px',
    },

    '& .Mui-disabled *': {
      WebkitTextFillColor: 'unset !important',
      border: 'unset',
      color: 'currentColor !important',
    },

    '& *::placeholder': {
      opacity: `1 !important`,
    },
  }) as SxProps

export const bodyStyle = (
  isPinnedStationDrawerOpen: boolean,
  studyHeaderTitleHeight: number,
): SxProps =>
  css({
    overflow: isPinnedStationDrawerOpen ? 'hidden' : 'auto',
    margin: `calc(${studyHeaderTitleHeight}px) 0 0 0`,
    padding: '0 24px 0 16px',
    zIndex: 0,
    [theme.mediaQueryMin.md]: {
      padding: isPinnedStationDrawerOpen ? '0' : `0 16px 0 16px`,
    },
    height: '100%',

    '& .Mui-disabled *': {
      WebkitTextFillColor: 'unset !important',
      border: 'unset',
      color: 'currentColor !important',
    },

    '& *::placeholder': {
      opacity: `1 !important`,
    },

    [theme.mediaQueryMax.mobile]: {
      margin: `16px 0 0 0`,
    },
  }) as SxProps

export const actionMenuDrawerStyle = (): SxProps =>
  css({
    '& > .MuiPaper-root': {
      height: 'auto',
      top: theme.appBarHeight,
      borderRight: '1px solid black',
      borderBottom: '1px solid black',
      gap: '8px',
      minWidth: '38%',
      padding: '8px 8px 4px 8px',
    },
  }) as SxProps

export const actionMenuStyle = (): SxProps =>
  css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'margin 0.5s',
    height: theme.actionMenuHeight,
    background: colors.gray.A400,
    padding: '0 32px',
    gap: '32px',
    overflow: 'hidden',
    borderBottom: `1px solid ${colors.lightBlue.A600}`,

    [theme.mediaQueryMax.mobile]: {
      position: 'absolute',
      height: 'auto',
      flexWrap: 'wrap',
      padding: '0 16px',
      gap: '0',
      borderBottom: `unset`,
    },
  }) as SxProps
export default headerStyle

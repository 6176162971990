import { i18n, Messages } from '@lingui/core'
import { en, fr, es } from 'make-plural/plurals'

import { messages as messagesEn } from '../locales/en/messages'
import { messages as messagesFr } from '../locales/fr/messages'
import { messages as messagesEs } from '../locales/es/messages'

i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr },
  es: { plurals: es },
})

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string): Promise<void> {
  const { messages } = await import(`../locales/${locale}/messages`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export function getInitialLanguage(): string {
  const language: string | null = localStorage.getItem('language')
  return language || 'en'
}

export function setInitialLanguage(language: string): void {
  localStorage.setItem('language', language)
}

export function activateInitialLanguage(): string {
  const language: string = getInitialLanguage()

  switch (language) {
    case 'fr':
      i18n.load(language, messagesFr as Messages)
      break
    case 'es':
      i18n.load(language, messagesEs as Messages)
      break
    default:
      i18n.load(language, messagesEn as Messages)
      break
  }
  i18n.activate(language)

  return language
}

/*
To avoid warning message:
  "I18nProvider did not render. A call to i18n.activate still needs to
  happen or forceRenderOnLocaleChange must be set to false."
*/
const initLanguage: string = getInitialLanguage()
i18n.load(initLanguage, {})
i18n.activate(initLanguage)

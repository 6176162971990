import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import reportWebVitals from 'reportWebVitals'
import 'shared/index.css'
import 'assets/roboto-fonts'
import App from 'App'
import AuthRoutes from 'pages/auth/AuthRoutes'
import Login from 'pages/auth/Login'
import Logout from 'pages/auth/Logout'
import SignUp from 'pages/auth/SignUp'
import ConfirmEmail from 'pages/account/ConfirmEmail'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import Study from 'pages/study/Study'
import AccountEdit from 'pages/account/AccountEdit'
import PasswordEdit from 'pages/account/PasswordEdit'
import ProtectedRoutes from 'pages/auth/ProtectedRoutes'
import AuthContainer from 'pages/auth/AuthContainer'
import NotFound from 'pages/NotFound'
import { ActionMenuContextProvider } from 'contexts/ActionMenuContext'
import theme from 'shared/theme'
import Projects from 'pages/projects/Projects'
import Project from 'pages/project/Project'
import Team from 'pages/team/Team'

const routing = (
  <ThemeProvider theme={theme}>
    <I18nProvider i18n={i18n}>
      <React.StrictMode>
        <SnackbarProvider
          maxSnack={1}
          autoHideDuration={2000}
          preventDuplicate
          disableWindowBlurListener
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ProtectedRoutes />}>
                <Route path="" element={<App />}>
                  <Route path="" element={<Projects />} />
                  <Route path="projects/:id" element={<Project />} />
                  <Route path="study/teams/add/" element={<Team />} />
                  <Route path="study/teams/:id/" element={<Team />} />
                  <Route path="study/teams/:id/setting/" element={<Team />} />
                  <Route
                    path="study/:id"
                    element={
                      <ActionMenuContextProvider>
                        <Study />
                      </ActionMenuContextProvider>
                    }
                  />
                  <Route path="account-edit/me" element={<AccountEdit />} />
                  <Route path="password-edit/me" element={<PasswordEdit />} />
                </Route>
              </Route>
              <Route path="/auth" element={<AuthRoutes />}>
                <Route path="" element={<AuthContainer />}>
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<SignUp />} />
                  <Route path="confirm-email" element={<ConfirmEmail />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />
                </Route>
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </React.StrictMode>
    </I18nProvider>
  </ThemeProvider>
)

ReactDOM.render(routing, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme from 'shared/theme'

const stationColors = theme.palette.station
const gridItemColors = theme.palette.gridItem

const getStationBorderStyle = (isActive: boolean, isDragging: boolean) => {
  if (isActive && !isDragging) {
    return `2px solid ${stationColors.drag.border}`
  }
  if (isDragging) {
    return `1px solid ${stationColors.drag.border}`
  }
  return `1px solid ${stationColors.main.border}`
}

const getStationItemMinHeightPinned = (
  studyRowCount: number,
  screenHeight: number,
  viewType: 'grid' | 'pin',
  pinnedStationCount: number,
  topHeaderHeight: number,
) => {
  if (viewType === 'pin') {
    if (pinnedStationCount === 1)
      return `calc(${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight} - 52px)`

    if (pinnedStationCount >= 2)
      return `calc((${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight}) / 2 - 32px)`
  }

  if (studyRowCount === 1) {
    return `calc((${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight}) / 2 - 38px)`
  }

  if (studyRowCount >= 2) {
    return `calc((${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight}) / 3 - 33px)`
  }

  return `calc(${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight} - 53px)`
}

const getStationItemMinHeight = (
  studyRowCount: number,
  screenHeight: number,
  topHeaderHeight: number,
) => {
  if (studyRowCount >= 2) {
    return `calc((${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight}) / 3 - 31px)`
  }

  if (studyRowCount >= 1) {
    return `calc((${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight}) / 2 - 35px)`
  }

  return `calc(${screenHeight}px - ${topHeaderHeight}px - ${theme.appBarHeight} - 48px)`
}

const stationStyle = (
  studyRowCount: number,
  screenHeight: number,
  isActive: boolean,
  isDragging: boolean,
  isPinnedStationDrawerOpen: boolean,
  viewType: 'grid' | 'pin',
  pinnedStationCount: number,
  topHeaderHeight: number,
  isStationPinned: boolean,
): SxProps =>
  css({
    height: isDragging ? '100%' : '100%',
    minHeight: isPinnedStationDrawerOpen
      ? getStationItemMinHeightPinned(
          studyRowCount,
          screenHeight,
          viewType,
          pinnedStationCount,
          topHeaderHeight,
        )
      : getStationItemMinHeight(studyRowCount, screenHeight, topHeaderHeight),
    width: isDragging ? '100%' : '100%',
    border: getStationBorderStyle(isActive, isDragging),
    background: isDragging ? stationColors.drag.background : stationColors.main.background,
    padding: '16px 32px',
    borderRadius: '8px',
    opacity: isDragging || (isStationPinned && viewType !== 'pin') ? '0.5' : '1',
    display: isStationPinned && viewType !== 'pin' ? 'none' : 'block',
    [theme.mediaQueryMax.mobile]: {
      padding: '8px 24px',
    },
  }) as SxProps

const getStationGridItemBorderStyle = (
  isDragging: boolean,
  isDraggingOver: boolean,
  isActive: boolean,
  hasStation: Boolean,
) => {
  if (isActive && !isDraggingOver) {
    return `2px solid ${gridItemColors.active.border}`
  }
  if (isDraggingOver) {
    return `1px solid ${gridItemColors.main.border}`
  }

  return hasStation && !isDragging && !isDraggingOver && !isActive
    ? 'unset'
    : `1px solid ${gridItemColors.main.border}`
}

export const stationGridItemStyle = (
  isDragging: boolean,
  isDraggingOver: boolean,
  columnLength: number,
  isActive: boolean,
  hasStation: boolean,
  studyRowCount: number,
  screenHeight: number,
  columnIndex: number,
  rowIndex: number,
  isPinnedStationDrawerOpen: boolean,
  viewType: 'grid' | 'pin',
  pinnedStationCount: number,
  topHeaderHeight: number,
): SxProps =>
  css({
    margin: (() => {
      if (viewType === 'pin') return '12px 0 0 0'

      if (isPinnedStationDrawerOpen) {
        if (rowIndex === 0) return columnIndex === 0 ? '0' : '0 0 0 24px'

        return columnIndex === 0 ? '24px 0 0 0' : '24px  0 0 24px'
      }

      return columnIndex === 0 ? '12px 0 12px 0' : '12px 0 12px 24px'
    })(),
    flexShrink: 0,
    minWidth: (() => {
      if (isPinnedStationDrawerOpen)
        return columnLength === 0 || viewType === 'pin' ? '100%' : 'calc(50% - 12px)'

      return columnLength === 0 ? '100%' : 'calc(50% - 12px)'
    })(),
    cursor: hasStation ? 'inherit' : 'pointer',
    height: 'auto',

    [theme.mediaQueryMax.mobile]: {
      minWidth: '100%',
    },

    '& .MuiPaper-station': {
      boxShadow: 'unset',
      background: 'transparent',
      border: getStationGridItemBorderStyle(isDragging, isDraggingOver, isActive, hasStation),
      borderRadius: '8px',
      minHeight: isPinnedStationDrawerOpen
        ? getStationItemMinHeightPinned(
            studyRowCount,
            screenHeight,
            viewType,
            pinnedStationCount,
            topHeaderHeight,
          )
        : getStationItemMinHeight(studyRowCount, screenHeight, topHeaderHeight),
      height: '100%',
    },
  }) as SxProps

export default stationStyle

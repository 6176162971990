import { Card, CardContent, Box, Typography, Button, Grid, IconButton } from '@mui/material'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import textMaxLineStyle from 'shared/textMaxLineStyle'
import styled from '@emotion/styled'
import IStudyListData from 'types/IStudyListData'
import { colors } from 'shared/theme'
import ProjectMoreActionMenu from 'pages/projects/ProjectMoreActionMenu'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import paths from 'utils/paths'

type ProjectCardProps = {
  studyList: IStudyListData
  setSelectedStudyList: Dispatch<SetStateAction<IStudyListData | null>>
  setProjectDialogOpen: Dispatch<SetStateAction<boolean>>
  teamId?: string
}

export default function ProjectCard(props: ProjectCardProps) {
  const { studyList, setSelectedStudyList, setProjectDialogOpen, teamId } = props
  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate: NavigateFunction = useNavigate()
  const lineHeight = '1.5em'
  const numberOfLines = 4
  const maxHeight = `calc(${lineHeight} * ${numberOfLines})`
  const TypographyMaxLine = styled(Typography)`
    ${textMaxLineStyle(lineHeight, maxHeight)}
  `

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card elevation={1} sx={{ height: '100%' }}>
        <CardContent
          sx={{ padding: '0 16px', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            margin="24px 0 16px 0"
          >
            <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
              {studyList.title && studyList.title !== '' ? studyList.title : t`New project`}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                onClick={() => setAnchorMenuOpen(!anchorMenuOpen)}
                ref={anchorMenuRef}
                size="small"
                sx={{ color: colors.gray.A500 }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <TypographyMaxLine
              variant="body1"
              paragraph
              whiteSpace="pre-wrap"
              sx={{ margin: 0, height: maxHeight }}
            >
              {studyList.note}
            </TypographyMaxLine>
          </Box>

          <Box display="flex" margin="24px 0 0 0" alignItems="flex-end" flexGrow={1}>
            <Button
              onClick={() => navigate(paths.frontend.studyList(studyList.id))}
              variant="contained"
              type="button"
              sx={{ width: '100%' }}
            >
              {studyList.is_default ? t`Start here` : t`Enter project`}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <ProjectMoreActionMenu
        studyList={studyList}
        anchorMenuOpen={anchorMenuOpen}
        setAnchorMenuOpen={setAnchorMenuOpen}
        anchorMenuRef={anchorMenuRef}
        setSelectedStudyList={setSelectedStudyList}
        setProjectDialogOpen={setProjectDialogOpen}
        teamId={teamId}
      />
    </Grid>
  )
}

import React, { useMemo, useRef, useState } from 'react'
import { Box, Button, DialogActions, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { t } from '@lingui/macro'
import useTemplates from 'hooks/useTemplates'
import Select, { SelectMenuItemData } from 'components/control/Select'
import { colors } from 'shared/theme'
import DialogComponent from 'components/dialog/DialogComponent'
import { useErrorContext } from 'contexts/ErrorContext'

type TemplateDialogProps = {
  description: string
  templatesEndpoint: string
  onClose: () => void
  onImportFromTemplate: (templateId: string) => Promise<unknown>
  open: boolean
  title: string
}

function TemplateDialog({
  description,
  templatesEndpoint,
  onClose,
  onImportFromTemplate,
  open,
  title,
}: TemplateDialogProps): JSX.Element {
  const { data: templates } = useTemplates(templatesEndpoint)
  const { handleAsyncError } = useErrorContext()
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('')
  const [isTemplateLoading, setTemplateLoading] = useState(false)
  const dialogRef = useRef(null)

  const handleSelectChange = (e: SelectChangeEvent<string>): void => {
    setSelectedTemplateId(e.target.value)
  }

  const handleImportClick = (): void => {
    if (selectedTemplateId !== '') {
      setTemplateLoading(true)
      onImportFromTemplate(selectedTemplateId)
        .then(() => {
          setSelectedTemplateId('')
          onClose()
        })
        .catch((err) => {
          handleAsyncError(err.message)
        })
        .finally(() => setTemplateLoading(false))
    }
  }

  const handleCloseDialog = (): void => {
    setSelectedTemplateId('')
    onClose()
  }

  const templateData: SelectMenuItemData[] = useMemo(
    () =>
      templates
        ?.map((t) => ({ value: t.id.toString(), text: t.title }))
        ?.sort((a, b) => (a.text > b.text ? 1 : -1)) ?? [],
    [templates],
  )

  return (
    <DialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={isTemplateLoading}
      dialogTitleNode={
        <Box padding="24px 24px 8px 24px">
          <Typography variant="h1" fontWeight="400" sx={{ color: colors.black.A600 }}>
            {title}
          </Typography>
        </Box>
      }
    >
      <Typography variant="h5" fontWeight="400" color="text">
        {' '}
        {description}
      </Typography>

      <Box margin="32px 0 0 0" className="MuiSelect-medium">
        <Select
          size="medium"
          errorText=""
          label={t`Select a template`}
          name="templates"
          onChange={handleSelectChange}
          options={templateData}
          value={selectedTemplateId}
        />
      </Box>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="16px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            disabled={isTemplateLoading}
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button
            variant="contained"
            onClick={handleImportClick}
            disabled={isTemplateLoading}
            sx={{ width: 'auto', minWidth: '100px' }}
          >
            {t`Import`}
          </Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  )
}

export default React.memo(TemplateDialog)

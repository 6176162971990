import { Button, Typography, Alert, Box, IconButton, Divider } from '@mui/material'
import { useForm } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import { t } from '@lingui/macro'
import React, { useEffect, useMemo } from 'react'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import ITermData from 'types/ITermData'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { colors } from 'shared/theme'
import { debounce } from 'lodash'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import TermDataService from 'data-services/TermDataService'
import { termMutate } from 'hooks/studyMutations'
import { ContentCopy as ContentCopyIcon, Link as LinkIcon } from '@mui/icons-material'
import TextFieldComponent from 'components/control/TextFieldComponent'
import TermUploadForm from './TermUploadForm'

type TermFormProps = {
  study: IStudyData | null
  mutateStudy: KeyedMutator<IStudyData>
  onModalClose: () => void
}

export default function TermForm(props: TermFormProps) {
  const { study, mutateStudy, onModalClose } = props
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const formSchema = yup.object().shape({
    hyperlink: yup
      .string()
      .trim()
      .url(t`Invalid URL`)
      .max(500, t`A maximum of ${500} characters are allowed`),
    note: yup.string(),
  })

  const {
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm<ITermData>({
    defaultValues: actionMenuContext.term
      ? useMemo(() => actionMenuContext.term, [actionMenuContext.term])
      : undefined,
    resolver: yupResolver(formSchema),
  })

  const handleTermFormSubmit = (data: ITermData) => {
    trigger().then((isValid) => {
      if (isValid) {
        TermDataService.update(actionMenuContext.term.id, data).then(({ data: termResponse }) => {
          void mutateStudy(
            termMutate(
              actionMenuContext.station.id,
              actionMenuContext.tool.id,
              actionMenuContext.term.id,
              termResponse,
            ),
            false,
          )
          setActionMenuContext((prevActionMenuContext) => ({
            ...prevActionMenuContext,
            term: termResponse,
          }))
        })
      }
    })
  }

  const getTermHyperlink = () =>
    actionMenuContext.term
      ? actionMenuContext.term.hyperlink ||
        actionMenuContext.term.image ||
        actionMenuContext.term.file
      : null
  const termHyperlink = getTermHyperlink()

  useEffect(() => {
    if (study) {
      const debouncedCb = debounce((formValue) => handleTermFormSubmit(formValue), 500)
      watch(debouncedCb)
    }
  }, [watch, study?.id])

  const handleOpenHyperlink = () => {
    if (errors && termHyperlink) {
      window.open(termHyperlink, '_blank')
    }
  }

  const handleCopyToClipboard = (textToCopy: string) => {
    if (errors) {
      void navigator.clipboard.writeText(textToCopy)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Typography color="text" variant="h1" fontWeight={400}>
        {t`Term information`}
      </Typography>

      <FormContainer>
        {errors.root?.serverError && (
          <Box margin="0 0 15px 0">
            <Alert severity="error">{errors.root?.serverError.message}</Alert>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          <Box display="flex" gap="16px">
            <Box
              width="86%"
              sx={{
                background: 'transparent',
              }}
            >
              <TextFieldComponent
                control={control as never}
                name="hyperlink"
                type="text"
                label={t`Hyperlink`}
                placeholder={t`Type a valid url...`}
                errors={errors}
                sx={{ background: 'white', width: '100%' }}
              />
            </Box>

            <Box
              display="flex"
              gap="16px"
              width="14%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              alignItems={{ xs: 'flex-end', md: 'center' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <IconButton
                onClick={handleOpenHyperlink}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={!termHyperlink}
              >
                <LinkIcon sx={{ fontSize: '24px' }} />
              </IconButton>

              <IconButton
                onClick={() => handleCopyToClipboard(actionMenuContext.term.hyperlink)}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={actionMenuContext.term.hyperlink === ''}
              >
                <ContentCopyIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Box>

          <TermUploadForm mutateStudy={mutateStudy} />

          <Divider sx={{ borderColor: colors.lightBlue.A600, margin: '16px 0 16px 0' }} />

          <Box display="flex" gap="8px">
            <Box
              width="85%"
              sx={{
                background: 'transparent',
              }}
            >
              <TextFieldComponent
                control={control as never}
                name="note"
                type="text"
                label={t`Term details`}
                placeholder={t`Type text...`}
                errors={errors}
                multiline
                sx={{ background: 'white', width: '100%' }}
              />
            </Box>

            <Box display="flex" gap="8px" width="15%" justifyContent="flex-end">
              <IconButton
                onClick={() => handleCopyToClipboard(actionMenuContext.term.note)}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={actionMenuContext.term.note === ''}
              >
                <ContentCopyIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ borderColor: colors.lightBlue.A600, margin: '16px 0 16px 0' }} />

          <Box display="flex" gap="8px">
            <Box
              width="100%"
              sx={{
                background: 'transparent',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={500}
                sx={{ color: colors.gray.A300 }}
              >{t`Agent Model`}</Typography>

              <Typography variant="body1" fontWeight={400} sx={{ color: colors.gray.A350 }}>
                {actionMenuContext.term.agent_model}
              </Typography>
            </Box>

            <Box display="flex" gap="8px">
              <IconButton
                onClick={() => handleCopyToClipboard(actionMenuContext.term.agent_model)}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={actionMenuContext.term.agent_model === ''}
              >
                <ContentCopyIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ borderColor: colors.lightBlue.A600, margin: '16px 0 16px 0' }} />

          <Box display="flex" gap="8px">
            <Box
              width="100%"
              sx={{
                background: 'transparent',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={500}
                sx={{ color: colors.gray.A300 }}
              >{t`Agent Token`}</Typography>

              <Typography variant="body1" fontWeight={400} sx={{ color: colors.gray.A350 }}>
                {actionMenuContext.term.agent_tokens}
              </Typography>
            </Box>

            <Box display="flex" gap="8px">
              <IconButton
                onClick={() => handleCopyToClipboard(actionMenuContext.term.agent_tokens)}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={actionMenuContext.term.agent_tokens === ''}
              >
                <ContentCopyIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ borderColor: colors.lightBlue.A600, margin: '16px 0 16px 0' }} />

          <Box display="flex" gap="8px">
            <Box
              width="100%"
              sx={{
                background: 'transparent',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={500}
                sx={{ color: colors.gray.A300 }}
              >{t`Agent Meta`}</Typography>

              <Typography variant="body1" fontWeight={400} sx={{ color: colors.gray.A350 }}>
                {actionMenuContext.term.agent_meta}
              </Typography>
            </Box>

            <Box display="flex" gap="8px">
              <IconButton
                onClick={() => handleCopyToClipboard(actionMenuContext.term.agent_meta)}
                sx={{ color: colors.black.A500, padding: 0 }}
                disableRipple
                disabled={actionMenuContext.term.agent_meta === ''}
              >
                <ContentCopyIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" margin="48px 0 16px 0" gap="8px">
            <Button
              variant="contained"
              type="button"
              sx={{ width: 'auto' }}
              onClick={() => onModalClose()}
            >
              {t`Done`}
            </Button>
          </Box>
        </Box>
      </FormContainer>
    </Box>
  )
}

import DialogComponent from 'components/dialog/DialogComponent'
import React, { useRef, useState } from 'react'
import { Box } from '@mui/material'
import ProjectForm from 'components/form/ProjectForm'
import IStudyListData from 'types/IStudyListData'
import ITeamData from 'types/ITeamData'

type ProjectDialogProps = {
  project: IStudyListData | null
  teams: ITeamData[] | null
  open: boolean
  onModalClose: () => void
  currentTeam?: ITeamData
}
export default function ProjectDialog(props: ProjectDialogProps) {
  const { project, teams, open, onModalClose, currentTeam } = props
  const dialogRef = useRef(null)
  const [isLoading, setLoading] = useState(false)

  return (
    <DialogComponent
      open={open}
      onModalClose={onModalClose}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={isLoading}
    >
      <Box sx={{ width: { xs: 'auto', md: '500px' } }}>
        <ProjectForm
          isLoading={isLoading}
          setLoading={setLoading}
          onModalClose={onModalClose}
          project={project}
          teams={teams}
          currentTeam={currentTeam}
        />
      </Box>
    </DialogComponent>
  )
}

import { Drawer, Backdrop } from '@mui/material'
import React, { useEffect } from 'react'
import { ResponsiveStyleValue } from '@mui/system/styleFunctionSx/styleFunctionSx'

type DrawerComponentProps = {
  openDrawer: boolean
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  className: string
  children: React.ReactNode
  width: ResponsiveStyleValue<string>
  showBackdrop: boolean
  anchor?: 'bottom' | 'left' | 'right' | 'top'
}

export default function DrawerComponent(props: DrawerComponentProps) {
  const { openDrawer, className, children, width, setOpenDrawer, showBackdrop, anchor } = props

  useEffect(() => {
    if (openDrawer) {
      document.body.classList.add('MuiDialog-open')
    }

    return () => {
      document.body.classList.remove('MuiDialog-open')
    }
  }, [openDrawer])

  return (
    <>
      <Drawer
        hideBackdrop={false}
        className={className && openDrawer ? `${className} active` : className}
        variant="permanent"
        anchor={anchor ?? 'left'}
        sx={{
          width: openDrawer ? width ?? '33%' : '0',
          margin: '0',
          position: { xs: 'absolute', md: 'initial' },
        }}
        PaperProps={{
          sx: {
            position: { xs: 'absolute', md: 'initial' },
          },
        }}
      >
        {children}
      </Drawer>
      {showBackdrop && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openDrawer}
          onClick={() => setOpenDrawer(false)}
        />
      )}
    </>
  )
}

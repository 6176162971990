import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'
import theme from 'shared/theme'

const toolColors = theme.palette.tool
const gridItemColors = theme.palette.gridItem

const getToolBorderStyle = (isActive: boolean, isDragging: boolean) => {
  if (isActive && !isDragging) {
    return `2px solid ${toolColors.drag.border}`
  }
  if (isDragging) {
    return `1px solid ${toolColors.drag.border}`
  }
  return `1px solid ${toolColors.main.border}`
}

const toolStyle = (
  screenHeight: number,
  studyRowCount: number,
  toolRowCount: number,
  isActive = false,
  isDragging = false,
): SxProps =>
  css({
    minHeight: 0,
    padding: '16px',
    height: '100%',
    boxShadow: 'unset',
    background: isDragging ? toolColors.drag.background : toolColors.main.background,
    border: getToolBorderStyle(isActive, isDragging),
    borderRadius: '8px',
  }) as SxProps

const getToolGridItemBorderStyle = (
  isDraggingOver: boolean,
  isActive: boolean,
  hasTool: Boolean,
) => {
  if (isActive && !isDraggingOver) {
    return `2px solid ${gridItemColors.active.border}`
  }
  if (isDraggingOver) {
    return `1px solid ${gridItemColors.main.border}`
  }

  return hasTool ? 'unset' : `1px solid ${gridItemColors.main.border}`
}

const getToolWidthPinned = (
  screenWidth: number,
  studyColumnCount: number,
  toolColumnCount: number,
  isPinView = false,
) => {
  if (isPinView) return `calc(${screenWidth / 3}px - 100px)`

  if (studyColumnCount > 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 3}px - 90px)`
  }

  if (studyColumnCount === 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 3}px - 80px)`
  }

  if (studyColumnCount > 0 && toolColumnCount === 0) {
    return `calc(${screenWidth / 3}px - 120px)`
  }

  return `calc(${screenWidth / 1.5}px - 150px)`
}

const getToolWidth = (
  screenWidth: number,
  studyColumnCount: number,
  toolColumnCount: number,
  isMobile = false,
) => {
  if (isMobile) return `calc(${screenWidth}px - 100px)`

  if (studyColumnCount > 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 4}px - 75px)`
  }

  if (studyColumnCount === 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 2}px - 75px)`
  }

  if (studyColumnCount > 0 && toolColumnCount === 0) {
    return `calc(${screenWidth / 2}px - 140px)`
  }

  return `calc(${screenWidth}px - 125px)`
}

export const toolGridItemStyle = (
  isDraggingOver: boolean,
  isActive: boolean,
  screenWidth: number,
  screenHeight: number,
  toolColumnCount: number,
  studyColumnCount: number,
  toolRowCount: number,
  studyRowCount: number,
  hasTool: boolean,
  columnIndex: number,
  isPinnedStationDrawerOpen: boolean,
  viewType: 'grid' | 'pin',
): SxProps =>
  css({
    minWidth: isPinnedStationDrawerOpen
      ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
      : getToolWidth(screenWidth, studyColumnCount, toolColumnCount, true),
    [theme.mediaQueryMin.md]: {
      minWidth: isPinnedStationDrawerOpen
        ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
        : getToolWidth(screenWidth, studyColumnCount, toolColumnCount),
    },
    height: 'auto',
    margin: columnIndex === 0 ? '24px 0 0 0' : '24px 0 0 32px',
    maxWidth: isPinnedStationDrawerOpen
      ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
      : 'unset',

    '& .MuiPaper-tool': {
      minHeight: 0,
      width: '100%',
      height: '100%',
      boxShadow: 'unset',
      background: 'transparent',
      border: getToolGridItemBorderStyle(isDraggingOver, isActive, hasTool),
    },
  }) as SxProps

export default toolStyle

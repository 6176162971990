import DialogComponent from 'components/dialog/DialogComponent'
import React, { useRef } from 'react'
import { Box } from '@mui/material'
import IStudyData from 'types/IStudyData'
import { KeyedMutator } from 'swr'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import TermForm from 'components/form/TermForm'

type TermDialogProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  open: boolean
  onModalClose: () => void
}
export default function TermDialog(props: TermDialogProps) {
  const { study, mutateStudy, open, onModalClose } = props
  const { actionMenuContext } = useActionMenuContext()
  const dialogRef = useRef(null)

  return (
    <DialogComponent
      open={open}
      onModalClose={onModalClose}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={false}
    >
      <Box sx={{ width: { xs: 'auto', md: '500px' } }}>
        {actionMenuContext.term && (
          <TermForm study={study} onModalClose={onModalClose} mutateStudy={mutateStudy} />
        )}
      </Box>
    </DialogComponent>
  )
}

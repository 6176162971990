import { Controller, DeepRequired, FieldErrorsImpl } from 'react-hook-form'
import { Box, FormControl, TextField, TextFieldProps } from '@mui/material'
import React from 'react'

export type FormTextFieldProps = TextFieldProps & {
  name: string
  control: never
  helperText?: string
  errors?: FieldErrorsImpl<DeepRequired<unknown>>
  inputAdornment?: React.ReactNode
}

function FormTextFieldBlur({
  placeholder,
  name,
  control,
  helperText,
  errors,
  InputProps,
  inputAdornment,
  ...others
}: FormTextFieldProps): JSX.Element {
  return (
    <>
      <Box display="flex" sx={{ width: '100%' }}>
        <Controller
          control={control}
          name={name as never}
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ width: '100%' }}>
              <TextField
                {...others}
                placeholder={placeholder}
                onChange={(e) => {
                  onChange(e)
                }}
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length,
                  )
                }
                value={value ?? ''}
                error={errors && !!errors[name]}
                helperText={errors && errors[name] ? errors[name]?.message : helperText}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault()
                  }
                }}
                InputProps={{
                  ...InputProps,
                  endAdornment: inputAdornment || null,
                }}
              />
            </FormControl>
          )}
        />
      </Box>
    </>
  )
}

export default FormTextFieldBlur

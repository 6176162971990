import { UseFormRegisterReturn } from 'react-hook-form'
import { TextField, TextFieldProps } from '@mui/material'

export type FormTextFieldProps = TextFieldProps & {
  errorText?: string | null
  registerReturn?: UseFormRegisterReturn
}

function FormTextField({
  errorText,
  registerReturn,
  sx,
  ...others
}: FormTextFieldProps): JSX.Element {
  return (
    <TextField
      {...others}
      {...(errorText && { error: true, helperText: errorText })}
      {...(registerReturn && { ...registerReturn })}
      sx={{ width: '100%', ...sx }}
    />
  )
}

export default FormTextField

import { SxProps } from '@mui/material/styles'
import { css } from '@emotion/react'

const floatingActionMenuStyle = (
  isStationPin: boolean,
  positionTop = '-16px',
  positionRight = '0',
): SxProps =>
  css({
    display: 'flex',
    alignItems: 'center',

    top: positionTop,
    right: positionRight,
    background: 'transparent',
    boxShadow: 'unset',
    borderRadius: 'unset',
    zIndex: 1,
    opacity: isStationPin ? 0.5 : 1,
    gap: '8px',

    '& .MuiIconButton-root': {
      padding: '0 8px',
    },
  }) as SxProps

export default floatingActionMenuStyle

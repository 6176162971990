import { css } from '@emotion/react'
import theme, { colors } from 'shared/theme'

const sideMenuDrawerStyle = css({
  height: `calc(100vh - ${theme.appBarHeight})`,
  zIndex: 1000,
  left: 0,
  boxShadow: 'unset',
  borderRadius: 'unset',
  border: 'unset',
  margin: '64px 0 0 0',

  [theme.mediaQueryMin.md]: {
    position: 'fixed',
  },

  '& .MuiPaper-root': {
    height: '100%',
    width: '100%',
    borderRight: `1px solid ${colors.lightBlue.A600}`,
    borderRadius: 'unset',
  },

  '& ::-webkit-scrollbar': {
    display: 'none',
  },
})

export default sideMenuDrawerStyle

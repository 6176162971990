import React, { useRef, useState } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { t } from '@lingui/macro'
import { toolMutate } from 'hooks/studyMutations'
import IToolData, { LANGUAGES, getLanguageText, getToolTypes } from 'types/IToolData'
import ToolDataService from 'data-services/ToolDataService'
import Checkbox from 'components/control/Checkbox'
import Select, { SelectMenuItemData } from 'components/control/Select'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import DialogComponent from 'components/dialog/DialogComponent'

type ToolDialogProps = {
  onClose: () => void
  open: boolean
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
}

function ToolDialog({ onClose, open, tool, mutateStudy }: ToolDialogProps): JSX.Element {
  const { setActionMenuContext } = useActionMenuContext()
  const [isLoading, setLoading] = useState(false)
  const [isTemplate, setIsTemplate] = useState<boolean>(tool.is_template)
  const [sourceLanguage, setSourceLanguage] = useState<string>(tool.source_language)
  const [destinationLanguage, setDestinationLanguage] = useState<string>(tool.destination_language)
  const [toolType, setToolType] = useState<string>(tool.tool_type)
  const dialogRef = useRef(null)
  const toolTypes = getToolTypes()

  const handleSaveDialog = (): void => {
    if (
      isTemplate !== tool.is_template ||
      sourceLanguage !== tool.source_language ||
      destinationLanguage !== tool.destination_language ||
      toolType !== tool.tool_type
    ) {
      const toolToUpdate: IToolData = {
        ...tool,
        ...{
          is_template: isTemplate,
          tool_type: toolType,
          source_language: sourceLanguage,
          destination_language: destinationLanguage,
        },
      }
      setLoading(true)
      ToolDataService.update(tool.id, toolToUpdate).then(({ data }) => {
        mutateStudy(toolMutate(tool.station, tool.id, data), false)
          .then(() => {
            onClose()
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              tool: toolToUpdate,
            }))
          })
          .finally(() => setLoading(false))
      })
    } else {
      onClose()
    }
  }

  const handleCloseDialog = (): void => {
    setIsTemplate(tool.is_template)
    setSourceLanguage(tool.source_language)
    setDestinationLanguage(tool.destination_language)
    setToolType(tool.tool_type)
    onClose()
  }

  const getLanguageData = (): SelectMenuItemData[] => {
    const languageData: SelectMenuItemData[] = []

    LANGUAGES.forEach((language: string) => {
      languageData.push({ value: language, text: getLanguageText(language) })
    })

    languageData.sort((a, b) => (a.text > b.text ? 1 : -1))
    return languageData
  }

  const getToolTypeData = (): SelectMenuItemData[] => {
    let allFound = false
    const toolTypeData: SelectMenuItemData[] = []

    toolTypes.forEach((toolType) => {
      if (toolType.id !== 'all') {
        toolTypeData.push({ value: toolType.id, text: toolType.text })
      } else {
        allFound = true
      }
    })

    toolTypeData.sort((a, b) => (a.text > b.text ? 1 : -1))
    if (allFound) {
      // If "all" tool type exists, it's put in the first position
      toolTypeData.splice(0, 0, { value: 'all', text: t`all` })
    }
    return toolTypeData
  }

  const getToolDialogTitle = () => {
    if (tool.tool_type) return tool.tool_type

    if (tool.title) return tool.title

    return t`Tool title`
  }

  return (
    <DialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={isLoading}
      dialogTitle={`${t`Configure tool`}${t`:`} ${getToolDialogTitle()}`}
    >
      <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 4 }}>
        {t`Tool:`} ({tool.title})
      </DialogContentText>
      <Box>
        <Checkbox
          checked={isTemplate}
          color="primary"
          label={<Typography sx={{ fontSize: '0.95rem' }}>{t`This tool is a template`}</Typography>}
          labelPlacement="end"
          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            setIsTemplate(checked)
          }
          sx={{ py: 0 }}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Select
          label={t`Tool type`}
          onChange={(e: SelectChangeEvent<string>) => setToolType(e.target.value)}
          options={getToolTypeData()}
          sx={{ fontSize: '0.95rem' }}
          value={toolType}
        />
      </Box>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="24px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            disabled={isLoading}
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveDialog}
            disabled={isLoading}
            sx={{ width: 'auto' }}
          >
            {t`Save`}
          </Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  )
}

export default React.memo(ToolDialog)

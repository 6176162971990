import React, { createContext, useState, useContext, useMemo, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

interface drawerComponentContextType {
  drawerOpen: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const drawerComponentContext = createContext<drawerComponentContextType | undefined>(undefined)

export const useDrawerComponentContext = () => {
  const context = useContext(drawerComponentContext)
  if (!context) {
    throw new Error(
      'useContextStudyEventStates must be used within a DrawerComponentContextProvider',
    )
  }
  return context
}

export const DrawerComponentContextProvider: React.FC = ({ children }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    setDrawerOpen(mdUp)
  }, [mdUp])

  const value = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen,
    }),
    [drawerOpen],
  )

  return <drawerComponentContext.Provider value={value}>{children}</drawerComponentContext.Provider>
}

import { useState, useEffect } from 'react'

type WindowDimensionProps = {
  height: number
  width: number
}

function getWindowDimension(): WindowDimensionProps {
  const { innerHeight: height, innerWidth: width } = window
  return { height, width }
}

export default function useWindowDimension(): WindowDimensionProps {
  const [windowDimension, setWindowDimension] = useState(getWindowDimension())

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimension())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimension
}

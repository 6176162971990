import React, { createContext, useState, useContext, useMemo } from 'react'
import IToolData from 'types/IToolData'
import IStationData from 'types/IStationData'
import ITermData from 'types/ITermData'
import { escapeRegExp } from 'lodash'

export type ActionMenuContextObject = {
  stationViewType?: string | null
  stationGridItem?: string | null
  station?: IStationData | null
  toolGridItem?: string | null
  tool?: IToolData | null
  stationListId?: string | null
  termGridItem?: string | null
  term?: ITermData | null
}

interface ActionMenuContextType {
  actionMenuContext?: ActionMenuContextObject
  setActionMenuContext: React.Dispatch<React.SetStateAction<ActionMenuContextObject>>
  isContextMenuLoading: boolean
  setContextMenuLoading: React.Dispatch<React.SetStateAction<boolean>>
  resetActionMenuContext: () => void
  formatActionMenuGridKey: (gridKey: string) => string
}

const ActionMenuContext = createContext<ActionMenuContextType | undefined>(undefined)

export const useActionMenuContext = (): ActionMenuContextType => {
  const context = useContext(ActionMenuContext)
  if (!context) {
    throw new Error('useContextMenu must be used within a ActionMenuContextProvider')
  }
  return context
}

export const ActionMenuContextProvider: React.FC = ({ children }) => {
  const [isContextMenuLoading, setContextMenuLoading] = useState(false)
  const actionMenuContextNull = {
    stationGridItem: null,
    station: null,
    toolGridItem: null,
    tool: null,
    stationListId: null,
    termGridItem: null,
    term: null,
  }
  const [actionMenuContext, setActionMenuContext] =
    useState<ActionMenuContextObject>(actionMenuContextNull)

  const resetActionMenuContext = () => {
    setActionMenuContext(actionMenuContextNull)
  }

  const formatActionMenuGridKey = (gridKey: string): string => {
    const substringsToRemove = ['-pin', '-grid']
    return gridKey.replace(
      new RegExp(substringsToRemove.map((s) => escapeRegExp(s)).join('|'), 'g'),
      '',
    )
  }

  const contextValue = useMemo(
    () => ({
      actionMenuContext,
      setActionMenuContext,
      resetActionMenuContext,
      isContextMenuLoading,
      setContextMenuLoading,
      formatActionMenuGridKey,
    }),
    [actionMenuContext, isContextMenuLoading],
  )

  return <ActionMenuContext.Provider value={contextValue}>{children}</ActionMenuContext.Provider>
}

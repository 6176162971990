import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Alert, Box, Button, CircularProgress, Grid, Link, Typography } from '@mui/material'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../../components/control/ProgressBackdrop'
import AccountDataService from '../../data-services/AccountDataService'
import IUserData from '../../types/IUserData'
import FormTextField from '../../components/control/FormTextField'

type FormResendFields = {
  email: string
}

export default function ConfirmEmail(): JSX.Element {
  console.log('Load Confirm Email Component')

  const navigate: NavigateFunction = useNavigate()
  const [key] = useState<string | null>((): string | null => {
    const urlParams = new URLSearchParams(window.location.search)
    const k = urlParams?.get('k')
    if (k) {
      return k
    }
    return null
  })
  const [isValidKey, setIsValidKey] = useState<boolean | null>(null)
  const [user, setUser] = useState<IUserData | null>(null)
  const [submitSuccess, setSubmitSuccess] = useState<string>('')
  const [submitWarning, setSubmitWarning] = useState<string>('')
  const [submitError, setSubmitError] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .label(t`The email address`)
      .required()
      .email(),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormResendFields>({
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (key) {
      AccountDataService.getUserInfoFromEmailVerificationKey(key)
        .then((response) => {
          const user: IUserData | null = response.data
          console.log('Successful getting user information', user)
          if (user !== null) {
            if (user.verified) {
              navigate('/auth/login')
            }
            setIsValidKey(true)
            setUser(user)
          }
        })
        .catch((err) => {
          console.log(
            'ERROR: An error occurred while getting user info from key',
            err,
            err.response,
          )
          if (err.response.status === 400 && err.response.data === 'expired_or_invalid_link') {
            setIsValidKey(false)
          } else {
            setSubmitError(t`An error occurred while getting user info, please try again.`)
          }
        })
    } else {
      setIsValidKey(false)
    }
  }, [])

  const handleConfirmSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setSubmitError('')

    if (key) {
      setSubmitting(true)
      AccountDataService.confirmEmail(key)
        .then(() => {
          console.log('Successful email confirmation')
          navigate('/auth/login')
        })
        .catch((err) => {
          console.log('ERROR: An error occurred during email confirmation', err, err.response)
          setSubmitError(t`An error occurred during confirmation, please try again.`)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleResendSubmit = (data: FormResendFields): void => {
    setSubmitSuccess('')
    setSubmitError('')
    setSubmitting(true)

    AccountDataService.resendVerificationEmail(data.email.toLowerCase())
      .then((response) => {
        const user: IUserData = response.data
        console.log('Successful sending email verification', user)
        setUser(user)
        if (user.verified) {
          setSubmitSuccess(t`This email is already associated with an activated account.`)
        } else {
          setSubmitSuccess(
            t`We have sent an email to you for verification. Follow the link provided to finalize the signup process.`,
          )
        }
      })
      .catch((err) => {
        console.log('ERROR: An error occurred while sending verification email', err, err.response)
        if (err.response.status === 400 && err.response.data === 'email_not_exists') {
          setSubmitWarning(t`This email not exists, please choose another one.`)
        } else {
          setSubmitError(t`An error occurred while resending verification email, please try again.`)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleGoToLogin = (): void => {
    navigate('/auth/login')
  }

  return (
    <>
      <Typography component="h1" variant="h6">
        {t`Confirm your email`}
      </Typography>
      {isValidKey !== false ? (
        <Box component="form" noValidate onSubmit={handleConfirmSubmit} sx={{ width: '100%' }}>
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            {isValidKey === null && submitError === '' && <CircularProgress sx={{ p: 0.5 }} />}
            {isValidKey === true && submitError === '' && (
              <span>{t`Please confirm that ${user?.email} is ${user?.name}'s email address.`}</span>
            )}
          </Box>
          {submitError !== '' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {submitError}
            </Alert>
          )}
          <Button
            color="primary"
            disabled={isValidKey !== true}
            fullWidth
            sx={{ mb: 2, mt: 3 }}
            type="submit"
            variant="contained"
          >
            {t`Confirm`}
          </Button>
        </Box>
      ) : (
        <Box component="form" noValidate onSubmit={handleSubmit(handleResendSubmit)}>
          <Box sx={{ mb: 2, mt: 3, textAlign: 'center' }}>
            {t`This email verification link has expired or is invalid. Please send a new email confirmation request.`}
          </Box>
          <FormTextField
            autoComplete="off"
            autoFocus
            errorText={errors.email?.message}
            label={t`Email Address`}
            margin="normal"
            registerReturn={register('email')}
          />
          {submitSuccess !== '' && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {submitSuccess}
            </Alert>
          )}
          {submitWarning !== '' && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              {submitWarning}
            </Alert>
          )}
          {submitError !== '' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {submitError}
            </Alert>
          )}
          {user?.verified ? (
            <Button
              color="primary"
              fullWidth
              onClick={() => handleGoToLogin()}
              sx={{ mb: 2, mt: 3 }}
              variant="contained"
            >
              {t`Sign In`}
            </Button>
          ) : (
            <>
              <Button
                color="primary"
                fullWidth
                sx={{ mb: 2, mt: 3 }}
                type="submit"
                variant="contained"
              >
                {t`Resend`}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/auth/login/" variant="body2">
                    {t`Sign In`}
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
      <ProgressBackdrop open={submitting} />
    </>
  )
}

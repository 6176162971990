import { Box, Typography, MenuItem, ListItemIcon, Divider } from '@mui/material'
import React, { Dispatch, RefObject, SetStateAction, useState } from 'react'
import { t } from '@lingui/macro'
import {
  DeleteForeverOutlined as DeleteForeverIcon,
  EditOutlined,
  SettingsOutlined as SettingsOutlinedIcon,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import PopperComponent from 'components/PopperComponent'
import { colors } from 'shared/theme'
import ConfirmDialog from 'components/control/ConfirmDialog'
import IStudyData from 'types/IStudyData'
import StudyDataService from 'data-services/StudyDataService'
import StudyConfigDialog from 'components/dialog/StudyConfigDialog'
import { KeyedMutator, MutatorCallback } from 'swr'
import { useNavigate } from 'react-router-dom'
import paths from 'utils/paths'

type StudyMoreActionMenuProps = {
  studyListId?: string
  study: IStudyData
  mutator: MutatorCallback
  anchorMenuOpen: boolean
  setAnchorMenuOpen: Dispatch<SetStateAction<boolean>>
  anchorMenuRef: RefObject<HTMLButtonElement>
  setSelectedStudy?: Dispatch<SetStateAction<IStudyData | null>>
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
}

export type DeleteDialogType = {
  open: boolean
  deleteType?: 'study' | 'station'
}

export default function StudyMoreActionMenu(props: StudyMoreActionMenuProps) {
  const {
    studyListId,
    study,
    mutator,
    anchorMenuOpen,
    setAnchorMenuOpen,
    anchorMenuRef,
    setSelectedStudy,
    setStudyDialogOpen,
  } = props
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<DeleteDialogType>({ open: false })
  const [configDialogOpen, setConfigDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleEditStudyClick = () => {
    if (setSelectedStudy) setSelectedStudy(study)

    setStudyDialogOpen(true)
    setAnchorMenuOpen(false)
  }

  const handleOpenConfigDialog = (): void => {
    setConfigDialogOpen(true)
  }

  const handleCloseConfigDialog = (): void => {
    setConfigDialogOpen(false)
  }

  const handleDeleteStudyClick = () => {
    setDeleteDialogOpen({ open: true, deleteType: 'study' })
    setAnchorMenuOpen(false)
  }

  const handleDeleteStudyConfirmClick = () => {
    const studyClone = study
    if (!studyListId) {
      navigate(paths.frontend.projects)
    }

    StudyDataService.deleteStudy(studyClone.id)
      .then(() => {
        mutator().then(() => {
          enqueueSnackbar(
            `Study "${
              studyClone.title && studyClone.title !== '' ? studyClone.title : t`New study`
            }" successfully deleted`,
            { variant: 'success' },
          )

          setDeleteDialogOpen({ open: false })
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  const handleDeleteStudyStationsConfirmClick = () => {
    StudyDataService.deleteStudyStations(study.id)
      .then(({ data: studyResponse }) => {
        const mutateStudy = mutator as unknown as KeyedMutator<IStudyData>
        mutateStudy(studyResponse, false).then(() => {
          enqueueSnackbar(t`Station(s) successfully deleted`, { variant: 'success' })
          setDeleteDialogOpen({ open: false })
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  return (
    <Box>
      <PopperComponent
        anchorMenu={anchorMenuOpen}
        setAnchorMenu={setAnchorMenuOpen}
        anchorRef={anchorMenuRef}
        backgroundColor={colors.lightBlue.A400}
        sx={{ minWidth: '185px', zIndex: 1 }}
      >
        <Box sx={{ padding: '8px' }}>
          <MenuItem onClick={handleEditStudyClick} sx={{ padding: '8px' }}>
            <ListItemIcon>
              <EditOutlined sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography variant="body1" fontWeight="400" color="text.primary">
              {t`Edit study`}
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleOpenConfigDialog} sx={{ padding: '8px' }}>
            <ListItemIcon>
              <SettingsOutlinedIcon sx={{ color: 'text.primary' }} />
            </ListItemIcon>
            <Typography variant="body1" fontWeight="400" color="text.primary">
              {t`Configure`}
            </Typography>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={handleDeleteStudyClick}
            className="MuiMenu-error"
            sx={{ padding: '8px' }}
          >
            <ListItemIcon>
              <DeleteForeverIcon color="error" />
            </ListItemIcon>
            <Typography variant="body1" fontWeight="400" color="error">
              {t`Delete Study`}
            </Typography>
          </MenuItem>
        </Box>
      </PopperComponent>
      <ConfirmDialog
        confirmColor="secondary"
        confirmText={deleteDialogOpen.deleteType === 'study' ? t`Delete study` : t`Delete stations`}
        description={
          deleteDialogOpen.deleteType === 'study'
            ? t`Are you sure you want to delete this study?`
            : t`Are you sure you want to delete the station(s)?`
        }
        open={deleteDialogOpen.open}
        onClose={() => setDeleteDialogOpen({ open: false, deleteType: null })}
        onConfirm={
          deleteDialogOpen.deleteType === 'study'
            ? handleDeleteStudyConfirmClick
            : handleDeleteStudyStationsConfirmClick
        }
        title={deleteDialogOpen.deleteType === 'study' ? t`Study` : t`Study station(s)`}
        confirmButtonVariant="containedRed"
        cancelButtonVariant="containedWhite"
      />
      <StudyConfigDialog
        open={configDialogOpen}
        onClose={handleCloseConfigDialog}
        study={study}
        mutator={mutator}
      />
    </Box>
  )
}

import React from 'react'
import { Button, Typography } from '@mui/material'

type ActionMenuAddToolItemProps = {
  toolType: string
  toolName: string
  handleAddToolClick: (toolType: string) => void
}

export default function ActionMenuAddToolItem({
  toolType,
  toolName,
  handleAddToolClick,
}: ActionMenuAddToolItemProps): JSX.Element {
  return (
    <Button
      onClick={() => handleAddToolClick(toolType)}
      variant="text"
      type="button"
      disableRipple
      sx={{ width: '100%', padding: '8px 24px' }}
    >
      <Typography variant="body1" fontWeight="400" color="text.primary" textAlign="left">
        {toolName}
      </Typography>
    </Button>
  )
}

import theme from 'shared/theme'
import { SxProps } from '@mui/material/styles'

const sideMenuDrawerRightBoxStyle = (withLeftMargin = true): SxProps => ({
  width: '100%',
  margin: {
    xs: `${parseInt(theme.appBarHeight, 10) + 24}px 0 0 0`,
    md: `${parseInt(theme.appBarHeight, 10) + 24}px 0 0 calc(${theme.sideMenuDesktopWidth} + ${
      withLeftMargin ? '24px' : '0px'
    })`,
  },
})
export default sideMenuDrawerRightBoxStyle

import useSWR from 'swr'
import { SWRResponse } from 'swr/_internal'
import ITemplateData from 'types/ITemplateData'
import axiosInstance from 'utils/axios'

function useTemplates(path: string): SWRResponse<ITemplateData[]> {
  return useSWR<ITemplateData[]>(path, (endpoint: string) =>
    axiosInstance.get(endpoint).then((data) => data?.data),
  )
}

export default useTemplates

import React, { useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  CheckBoxOutlined as CheckBoxIcon,
  ContentCopyOutlined as ContentCopyIcon,
  DeleteOutlined as DeleteIcon,
  InfoOutlined as InfoIcon,
  InputOutlined as InputIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { KeyedMutator } from 'swr'
import { useErrorContext } from 'contexts/ErrorContext'
import IStudyData, { IPasteDataInterface } from 'types/IStudyData'
import TermDataService from 'data-services/TermDataService'
import { colors } from '../../shared/theme'
import { IClipboard, IClipboardItemState } from '../../contexts/ClipboardContext'
import ITermData from '../../types/ITermData'
import StudyRepository from '../../repositories/StudyRepository'

type ActionMenuTermProps = {
  mutateStudy: KeyedMutator<IStudyData>
  setTermDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  clipboard: IClipboard
}

export default function ActionMenuTerm({
  mutateStudy,
  setTermDialogOpen,
  clipboard,
}: ActionMenuTermProps): JSX.Element {
  const {
    actionMenuContext,
    setActionMenuContext,
    isContextMenuLoading,
    setContextMenuLoading,
    resetActionMenuContext,
  } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()
  const actionButtonCopyMoveTermRef = useRef<HTMLButtonElement>(null)
  const [actionButtonCopyMoveTermOpen, setActionButtonCopyMoveTermOpen] = useState(false)
  StudyRepository.initialize(handleAsyncError)

  const deleteTerm = (termId: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      TermDataService.deleteTerm(termId)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleDeleteTerm = () => {
    if (actionMenuContext.term) {
      setContextMenuLoading(true)
      deleteTerm(actionMenuContext.term.id).then(() => {
        mutateStudy()
          .then(() => {
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              term: null,
            }))
          })
          .finally(() => setContextMenuLoading(false))
      })
    }
  }

  const handlePasteToClipboard = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)

    const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()

    if (!actionMenuContext || !actionMenuContext.tool || termStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const terms = clipboard.terms.getSelectedItemStates()
    let clippy = ''
    for (let i = 0; i < terms.length; i += 1) clippy += `${terms[i].data.term}\n`
    navigator.clipboard.writeText(clippy)
    clipboard.terms.removeSelectedItemStates()
    resetActionMenuContext()
    setContextMenuLoading(false)
  }

  const handlePasteFromClipboardSelectionTerms = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)

    const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()

    if (!actionMenuContext || !actionMenuContext.tool || termStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const terms = clipboard.terms.getSelectedItemStates()
    const toolPasteData: IPasteDataInterface = {
      term: actionMenuContext.term?.id ?? null,
      actions: terms.map((term) => ({
        action,
        term: term.data.id,
      })),
    }

    StudyRepository.pasteTerms(actionMenuContext.tool.id, toolPasteData)
      .then((studyResponse: IStudyData) => {
        mutateStudy(studyResponse, false).then(() => {
          clipboard.terms.removeSelectedItemStates()
          resetActionMenuContext()
        })
      })
      .finally(() => setContextMenuLoading(false))
  }

  return (
    <Box
      display="flex"
      gap={{ xs: '8px', mobile: '32px' }}
      flexGrow="1"
      alignItems="center"
      flexWrap={{ xs: 'wrap', mobile: 'nowrap' }}
    >
      {actionMenuContext.term && (
        <ActionMenuButton
          handleActionMenuButtonClick={() => setTermDialogOpen(true)}
          StartIcon={InfoIcon}
          title={t`View Term Info`}
          isButtonDisabled={isContextMenuLoading}
        />
      )}

      {actionMenuContext.term && clipboard.terms.getSelectedItemStates().length > 0 && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonCopyMoveTermRef}
          actionMenuButtonAnchorOpen={actionButtonCopyMoveTermOpen}
          setActionMenuButtonAnchorOpen={setActionButtonCopyMoveTermOpen}
          handleActionMenuButtonClick={() =>
            setActionButtonCopyMoveTermOpen(!actionButtonCopyMoveTermOpen)
          }
          StartIcon={CheckBoxIcon}
          title={`${t`Selected Terms`} (${clipboard.terms.getSelectedItemStates().length})`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelectionTerms('cut')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <InputIcon sx={{ color: colors.black.A500 }} className="MuiButton-startIcon" />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="400"
                    color="text.primary"
                    textAlign="left"
                    margin="0 0 0 8px"
                  >
                    {!actionMenuContext.tool ? t`Cut and Paste` : t`Cut and Paste`}
                  </Typography>
                </Button>
              </Box>

              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelectionTerms('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyIcon
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="400"
                    color="text.primary"
                    textAlign="left"
                    margin="0 0 0 8px"
                  >
                    {!actionMenuContext.tool ? t`Copy and Paste` : t`Copy and Paste`}
                  </Typography>
                </Button>
              </Box>
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteToClipboard('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyIcon
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    variant="body1"
                    fontWeight="400"
                    color="text.primary"
                    textAlign="left"
                    margin="0 0 0 8px"
                  >
                    {t`Copy to clipboard`}
                  </Typography>
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      {actionMenuContext.term && (
        <ActionMenuButton
          handleActionMenuButtonClick={() => handleDeleteTerm()}
          StartIcon={DeleteIcon}
          title={t`Delete Term`}
          isDanger
          isButtonDisabled={isContextMenuLoading}
        />
      )}
    </Box>
  )
}

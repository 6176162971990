import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Grid, Paper } from '@mui/material'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { t } from '@lingui/macro'
import { useTheme } from '@mui/material/styles'
import { IClipboard } from 'contexts/ClipboardContext'
import IStationData from 'types/IStationData'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import FormTextFieldBlur from 'components/control/FormTextFieldBlur'
import stationStyle from 'shared/components/stationStyle'
import IToolData from 'types/IToolData'
import { toolGridItemStyle } from 'shared/components/toolStyle'
import Tool from 'components/tool/Tool'
import { colors } from 'shared/theme'
import { KeyedMutator } from 'swr'
import IStudyData from 'types/IStudyData'
import useWindowDimensions from 'utils/useWindowDimensions'
import orderingHelper from 'utils/orderingHelper'
import { FormStudyData } from 'components/form/StudyDataForm'
import FloatingActionMenuStation from 'components/floating-action-menu/FloatingActionMenuStation'

type StationProps = {
  station: IStationData
  parentRow: number
  parentColumn: number
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function Station({
  station,
  parentRow,
  parentColumn,
  study,
  mutateStudy,
  clipboard,
  viewType,
}: StationProps): JSX.Element {
  const isInitialStationRender = useRef<boolean>(true)
  const { isPinnedStationDrawerOpen, topHeaderHeight } = useStudyStatesContext()
  const { width: screenWidth, height: screenHeight } = useWindowDimensions()
  const theme = useTheme()
  const { actionMenuContext, setActionMenuContext, formatActionMenuGridKey } =
    useActionMenuContext()
  const stationKey = `station${viewType === 'pin' ? '-pin' : ''}-${station.row}-${station.column}`
  const stationRef = useRef(null)

  const { control } = useFormContext<FormStudyData>()

  useEffect(() => {
    if (isInitialStationRender.current) {
      isInitialStationRender.current = false
    } else if (clipboard.stations.getItemState(station.id) !== null) {
      clipboard.stations.updateItemData(station)
    }
  }, [station])

  const isStationActive = (): boolean =>
    !!actionMenuContext &&
    actionMenuContext.station?.id === station.id &&
    actionMenuContext.toolGridItem === null &&
    actionMenuContext.term === null

  const handleStationClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setActionMenuContext({
      stationGridItem: `station-grid-item-${station.row}-${station.column}`,
      station,
      toolGridItem: null,
      tool: null,
      stationListId: null,
      term: null,
    })
  }

  const getInputProps = (name: string) => {
    const generalProps = {
      disableUnderline: true,
    }
    const generalStyles = {
      border: 'unset',
      margin: 0,
      padding: 0,
    }

    const config = {
      title: {
        ...theme.typography.h1,
        fontWeight: 400,
        color: colors.blue.A800,
      },
      note: {
        ...theme.typography.body1,
        fontWeight: 400,
        color: colors.gray.A600,
      },
    }

    const defaultStyle = {}
    const chosenStyle = config[name] || defaultStyle

    return {
      ...generalProps,
      style: {
        ...generalStyles,
        ...chosenStyle,
      },
      sx: {
        '& *::placeholder': {
          ...generalStyles,
          ...chosenStyle,
        },
      },
    }
  }

  const handleToolGridItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    gridItemKey: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const { row, column } = orderingHelper.getRowColumnByKey(gridItemKey)
    const tool = orderingHelper.findToolByRowColumn(station, row, column)

    if (!tool) {
      setActionMenuContext({
        stationGridItem: `station-grid-item-${station.row}-${station.column}`,
        station,
        toolGridItem: gridItemKey,
        tool: null,
        stationListId: null,
        term: null,
      })
    }
  }

  const findToolByRowColumn = (row: number, column: number): IToolData | null => {
    if (station) {
      return station.tools?.find((tool) => tool.row === row && tool.column === column)
    }
    return null
  }

  const isToolGridItemActive = (gridItemKey: string): boolean =>
    !!actionMenuContext &&
    actionMenuContext.toolGridItem &&
    formatActionMenuGridKey(actionMenuContext.toolGridItem) ===
      formatActionMenuGridKey(gridItemKey) &&
    actionMenuContext.tool === null

  return (
    station && (
      <Draggable
        draggableId={stationKey}
        index={station.column * 10000 + station.row}
        key={stationKey}
        isDragDisabled
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <Box
            ref={(node: HTMLElement) => {
              stationRef.current = node
              provided.innerRef(node)
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={stationStyle(
              study.row_count,
              screenHeight,
              isStationActive(),
              snapshot.isDragging,
              isPinnedStationDrawerOpen,
              viewType,
              study.stations.filter((stationObject) => stationObject.is_pinned).length,
              topHeaderHeight,
              station.is_pinned,
            )}
            onClick={handleStationClick}
          >
            <Box>
              <Box display="flex" sx={{ width: '100%' }}>
                <Box alignItems="center" sx={{ width: '100%' }}>
                  <Box
                    display="flex"
                    width="100%"
                    sx={{
                      background: 'transparent',
                    }}
                  >
                    <FormTextFieldBlur
                      name={`stations[${station.id}].title`}
                      autoComplete="off"
                      placeholder={t`Station title`}
                      InputProps={getInputProps('title')}
                      variant="standard"
                      multiline
                      control={control as never}
                    />
                    <FloatingActionMenuStation
                      study={study}
                      station={station}
                      clipboard={clipboard}
                      mutateStudy={mutateStudy}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    sx={{
                      background: 'transparent',
                      minHeight: '25px',
                    }}
                  >
                    <FormTextFieldBlur
                      name={`stations[${station.id}].note`}
                      autoComplete="off"
                      placeholder={t`Station description`}
                      InputProps={getInputProps('note')}
                      variant="standard"
                      multiline
                      control={control as never}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {[...Array(station.row_count + 1)].map((_, rowIndex) => (
              <Grid
                key={`station-${rowIndex}`}
                container
                wrap="nowrap"
                columnSpacing={0}
                padding="0"
                justifyContent="center"
              >
                {[...Array(station.column_count + 1)].map((_, columnIndex) => {
                  const toolDroppableKey = `${station.id}-tool-droppable${
                    viewType === 'pin' ? '-pin' : ''
                  }-${rowIndex}-${columnIndex}`
                  return (
                    <Droppable
                      key={toolDroppableKey}
                      droppableId={toolDroppableKey}
                      direction="vertical"
                      type="tool"
                      ignoreContainerClipping
                    >
                      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
                        const tool = findToolByRowColumn(rowIndex, columnIndex)
                        return (
                          <Grid
                            key={toolDroppableKey}
                            item
                            ref={provided.innerRef}
                            sx={toolGridItemStyle(
                              snapshot.isDraggingOver,
                              isToolGridItemActive(toolDroppableKey),
                              screenWidth,
                              screenHeight,
                              station.column_count,
                              study.column_count,
                              station.row_count,
                              study.row_count,
                              !!tool,
                              columnIndex,
                              isPinnedStationDrawerOpen,
                              viewType,
                            )}
                            onClick={(event) => handleToolGridItemClick(event, toolDroppableKey)}
                          >
                            <Paper className="MuiPaper-tool">
                              {provided.placeholder}
                              {tool && (
                                <Tool
                                  study={study}
                                  tool={tool}
                                  station={station}
                                  parentRow={parentRow}
                                  parentColumn={parentColumn}
                                  mutateStudy={mutateStudy}
                                  clipboard={clipboard}
                                  viewType={viewType}
                                />
                              )}
                            </Paper>
                          </Grid>
                        )
                      }}
                    </Droppable>
                  )
                })}
              </Grid>
            ))}
          </Box>
        )}
      </Draggable>
    )
  )
}

export default React.memo(Station)

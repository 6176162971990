import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Close as CloseIcon, HighlightOff as HighlightOffIcon } from '@mui/icons-material'
import { t } from '@lingui/macro'
import { IClipboard, IClipboardItems, IClipboardItemState } from 'contexts/ClipboardContext'
import IStudyData from 'types/IStudyData'
import IStationData from 'types/IStationData'
import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'
import Checkbox from 'components/control/Checkbox'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

const TypographySubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '12px',
  fontWeight: 'bold',
  paddingTop: '5px',
  textTransform: 'uppercase',
}))

type ClipboardDrawerProps = {
  onClose: () => void
  open: boolean
  clipboard: IClipboard
}

function ClipboardDrawer({
  onClose,
  open: initOpen,
  clipboard,
}: ClipboardDrawerProps): JSX.Element {
  const { pasteMenuCount } = useStudyStatesContext()
  const [open, setOpen] = useState(initOpen)
  const [drawerLoading, setDrawerLoading] = useState(false)

  useEffect(() => {
    setOpen(initOpen)
  }, [initOpen])

  const handleClose = (): void => {
    setOpen(false)
    onClose()
  }

  // Hack for display debug info
  const handleDisplayDebugInfo = (
    e: React.MouseEvent<HTMLDivElement>,
    clipboard: IClipboard,
  ): void => {
    e.stopPropagation()
    if (clipboard.studies.itemCount > 0) {
      console.log(
        'Debug (Clipboard Studies)',
        clipboard.studies.itemStateKeys,
        clipboard.studies.itemStateByKey,
      )
    }
    if (clipboard.stations.itemCount > 0) {
      console.log(
        'Debug (Clipboard Stations)',
        clipboard.stations.itemStateKeys,
        clipboard.stations.itemStateByKey,
      )
    }
    if (clipboard.tools.itemCount > 0) {
      console.log(
        'Debug (Clipboard Tools)',
        clipboard.tools.itemStateKeys,
        clipboard.tools.itemStateByKey,
      )
    }
    if (clipboard.terms.itemCount > 0) {
      console.log(
        'Debug (Clipboard Terms)',
        clipboard.terms.itemStateKeys,
        clipboard.terms.itemStateByKey,
      )
    }
  }

  function getItemText(item: unknown): string {
    if ((item as IStudyData).title) {
      return (item as IStudyData).title
    }
    if ((item as IStationData).title) {
      return (item as IStationData).title
    }
    if ((item as IToolData).tool_type) {
      return (item as IToolData).tool_type
    }
    if ((item as IToolData).title) {
      return (item as IToolData).title
    }
    if ((item as ITermData).term) {
      return (item as ITermData).term
    }
    return t`Unknown type`
  }

  const handleDrawerLoading = (clipboardAction: () => void) => {
    setDrawerLoading(true)
    clipboardAction()
    setTimeout(() => {
      setDrawerLoading(false)
    }, 500)
  }

  function renderClipboardItem<T>(
    clipboardItems: IClipboardItems<T>,
    title: string,
    removeAllItemsText: string,
    removeItemText: string,
  ): JSX.Element {
    if (clipboardItems.itemCount > 0) {
      return (
        <Box sx={{ pb: 2, pt: 1 }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Checkbox
                checked={clipboardItems.itemCount === clipboardItems.selectedItemCount}
                color="default"
                label={<TypographySubTitle>{title}</TypographySubTitle>}
                labelPlacement="end"
                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  handleDrawerLoading(() => clipboardItems.changeAllItemsSelection(checked))
                }
                size="small"
                sx={{ mt: '3px', py: 0 }}
                disabled={drawerLoading}
              />
            </Box>
            <Box>
              <Tooltip title={removeAllItemsText} disableInteractive>
                <IconButton
                  onClick={() => handleDrawerLoading(clipboardItems.removeAllItems)}
                  size="small"
                  sx={{ mx: 1, p: 0 }}
                  disabled={drawerLoading}
                >
                  <HighlightOffIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Divider sx={{ mb: 1, mt: '3px' }} />
          <Box>
            {clipboardItems.itemStateKeys.map((itemKey: string) => {
              const itemState: IClipboardItemState<T> = clipboardItems.itemStateByKey[itemKey]
              return (
                <Box display="flex" key={itemKey}>
                  <Box flexGrow={1}>
                    <Checkbox
                      checked={itemState.selected}
                      label={getItemText(itemState.data)}
                      labelPlacement="end"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleDrawerLoading(() =>
                          clipboardItems.changeItemSelection(checked, itemKey),
                        )
                      }
                      size="small"
                      sx={{ py: 0 }}
                      disabled={drawerLoading}
                    />
                  </Box>

                  <Box>
                    <Tooltip title={removeItemText} disableInteractive>
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleDrawerLoading(() => clipboardItems.removeItem(itemKey))
                        }
                        size="small"
                        sx={{ fontSize: '18px', mr: '9px', p: 0 }}
                        disabled={drawerLoading}
                      >
                        <HighlightOffIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
      )
    }
    return <Box />
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      onDoubleClick={(e) => handleDisplayDebugInfo(e, clipboard)}
    >
      <Box sx={{ minWidth: '350px', overflowX: 'hidden' }}>
        <DialogTitle sx={{ p: 2 }}>
          <Box display="flex">
            <Box flexGrow={1}>{t`Clipboard`}</Box>
            <Tooltip title={t`Close`} disableInteractive>
              <IconButton onClick={onClose} sx={{ color: 'text.secondary', mr: '5px', p: '3px' }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 2 }}>
          {pasteMenuCount === 0 && (
            <Typography
              color="text.secondary"
              fontSize="15px"
            >{t`The clipboard is empty.`}</Typography>
          )}
          {renderClipboardItem<IStudyData>(
            clipboard.studies,
            t`Studies`,
            t`Remove all studies`,
            t`Remove study`,
          )}
          {renderClipboardItem<IStationData>(
            clipboard.stations,
            t`Stations`,
            t`Remove all stations`,
            t`Remove station`,
          )}
          {renderClipboardItem<IToolData>(
            clipboard.tools,
            t`Tools`,
            t`Remove all tools`,
            t`Remove tool`,
          )}
          {renderClipboardItem<ITermData>(
            clipboard.terms,
            t`Terms`,
            t`Remove all terms`,
            t`Remove term`,
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          {clipboard.totalItemCount > 0 ? (
            <Button
              autoFocus
              color="primary"
              fullWidth
              onClick={clipboard.removeAll}
              size="small"
              variant="outlined"
            >
              {t`Clear Clipboard`}
            </Button>
          ) : (
            <Box />
          )}
        </DialogActions>
      </Box>
    </Drawer>
  )
}

export default React.memo(ClipboardDrawer)

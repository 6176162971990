import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import IStudyData from 'types/IStudyData'

const rootPath = '/studies'

const authenticated = (): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/authenticated`)

const importStudies = (
  studyListId: string,
  studies: IStudyData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    `${rootPath}/study-lists/${studyListId}/studies/import-from-file/`,
    studies,
  )

const voiceForTool = (toolId: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/voice-for-tool?tool_id=${toolId}`)

const voiceForTerm = (termId: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.get(`${rootPath}/voice-for-term?term_id=${termId}`)

const StudiesService = {
  authenticated,
  importStudies,
  voiceForTool,
  voiceForTerm,
}

export default StudiesService
